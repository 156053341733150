import React from 'react'
import slugify from 'slugify'

import Main from './Main'
import Activities from './Activities'
import Services from './Services'
import AboutUs from './AboutUs'
import YourActivities from './YourActivity'
import Camera from './Camera'
import { NavigationItemProps } from '../components/Navigation/NavigationItem'
import GymReservation from './GymReservation'
import SportFacility from './SportFacility'
import TrackFacility from './SportFacility/TrackFacility'
import NotFound from './NotFound'
import Standard from './Standard'
import StandardHtml from './StandardHtml'
import Hotel from './Hotel'

// const components: { [key: string]: (props: any) => JSX.Element } = {
//     'strona-glowna': Main,
//     aktywnosci: Activities,
//     'twoje-imprezy': YourActivities,
//     uslugi: Services,
//     'o-nas': AboutUs,
//     'kamera-plaza': Camera,
//     'zarezerwuj-sale': GymReservation,
//     // 'plywalnia-sportowa': (props: any) => <SportFacility {...props} showCalendar={true} />,
//     // 'plywalnia-rodzinna': (props: any) => <SportFacility {...props} showCalendar={true} />,
//     // 'plywalnia-kameralna': (props: any) => <SportFacility {...props} showCalendar={true} />,
//     // lodowisko: (props: any) => <SportFacility {...props} showCalendar={true} />,
//     // 'hotel-bosir': SportFacility,
//     // 'hotel': SportFacility,
//     // 'plaza-miejska-dojlidy': SportFacility,
//     // 'stadion-lekkoatletyczny': SportFacility,
//     'tor-bialystok': (props: any) => <TrackFacility {...props} />,
//     'not-found': NotFound,
// }

const getComponentByCategory = (cat: any) => {
    if (cat.name === 'Hotel') {
        return (props: any) => <Hotel {...props} />
    }
    if (cat.viewType === 'standard') {
        return (props: any) => { return <Standard {...props} /> }
    }
    if (cat.viewType === 'html') {
        return (props: any) => { return <StandardHtml {...props} /> }
    }
    if (cat.viewType === 'facility' && cat.name.indexOf('Tor') !== -1) {
        return (props: any) => <TrackFacility {...props} />
    }
    if (cat.viewType === 'facility') {
        return (props: any) => <SportFacility {...props} />
    }

    return NotFound
}

export const prepareRoutes = (routes: any): NavigationItemProps[] => {
    return routes.reduce((acc: any[], item: any) => {
        if (item.parent) {
            return acc
        }

        // allow this page to have separated view
        const mainWithChildren = [1050]

        const path = !mainWithChildren.includes(item.id) && item?.children?.length ? undefined : slugify(item.name, { lower: true })
        const routeItem = {
            name: item.name,
            label: item.name.toUpperCase() + (!path ? ' ▼' : ''),
            isMain: slugify(item.name, { lower: true }).indexOf('glowna') !== -1,
            path,
            rows: item.rows,
            calendar: item.calendar,
            subItems: routes
                .filter((cat: any) => cat.parent?.id === item.id)
                .map((cat: any) => {
                    const path = slugify(cat.name, { lower: true })
                    return {
                        label: cat.name.toUpperCase(),
                        path,
                        Component: getComponentByCategory(cat),
                        id: cat.id,
                        tilesPageUrl: cat.tilesPage,
                        calendar: cat.calendar,
                    }
                }),
            Component: getComponentByCategory(item),
            id: item.id,
            isMenu: item.isMenu,
            viewType: item.viewType,
        }

        return routeItem.isMain
            ? [{ path: '/', Component: Main, id: item.id, rows: item.rows }, ...acc]
            : [...acc, routeItem]
    }, [])
}
