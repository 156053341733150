import React from 'react'

import Tile, { ITile } from '../Tile'
import styles from './TilesRow.module.scss'
import LoadingTile from '../Tile/LoadingTile'
import { baseUrl } from '../../api/api'

interface TilesRowProps {
    row: ITile[]
    onTileClick?: (id: number) => void // @todo to remove
    children?: JSX.Element
}

const TilesRow = (props: TilesRowProps) => {
    const { row, children } = props

    const getTileImageFromJson = (image: any) => {
        if (typeof image === 'string') {
            if (image[0] === '{') {
                return JSON.parse(image.replace('/', '/'))
            } else {
                return { src: image }
            }
        }

        return image
    }
    return (
        <div className={styles.container}>
            {children}
            {row.map((r, i) =>
                r.isLoading ? (
                    <LoadingTile key={i} />
                ) : (
                    <Tile
                        key={i}
                        size={row.length}
                        tile={{
                            ...r,
                            image: {
                                ...r.image,
                                src:
                                    baseUrl +
                                    (getTileImageFromJson(r.image).src || './test_photo.jpg'),
                            },
                        }}
                    />
                )
            )}
        </div>
    )
}

export default TilesRow
