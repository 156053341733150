import React from "react";

interface ImageProps {
    src: string
    alt?: string
    className?: string
    title?: string
    onClick?: () => void
}

const Image = (props: ImageProps) => {
    const {
        src,
        alt = '',
        className = '',
        title = '',
        onClick,
    } = props
    
    return (
        <img
            src={src} 
            alt={alt} 
            className={className}
            onClick={onClick}
            title={title}
        />
    )
}

export default Image
