import styles from './CustomCalendar.module.scss'
import cn from 'classnames'
import { LANG_DAYS_PL } from '../Calendar/calendar.types'
import { useEffect, useState } from 'react'
import Button from '../Button'

interface CustomCalendarProps {
    type: string
    onClickDay: (day: Date) => void
    selectedDay?: Date | null
}

const CustomCalendar = ({ type, onClickDay, selectedDay = null }: CustomCalendarProps) => {
    const today = new Date()
    const [monday, setMonday] = useState<Date>(today)
    const naiveIsMobile = window.innerWidth <= 768
    const showDays = naiveIsMobile ? 6 : 7

    const nextMonday = () => {
        setMonday(new Date(+monday + showDays * 24 * 3600 * 1000))
    }

    const prevMonday = () => {
        setMonday(new Date(+monday - showDays * 24 * 3600 * 1000))
    }

    const renderDay = (date: Date) => {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return (
            <div
                key={date.toDateString()}
                onClick={() => onClickDay(date)}
                className={cn(
                    styles.day,
                    today.toDateString() === date.toDateString() && styles.today,
                    date.toDateString() === selectedDay?.toDateString() && styles.selected
                )}
            >
                {LANG_DAYS_PL[date.getDay()]}
                <br />
                {day < 10 ? '0' : ''}
                {day}.{month < 10 ? '0' : ''}
                {month}
                <br />
                {year !== today.getFullYear() ? year : ''}
            </div>
        )
    }

    const renderWeek = (monday: Date) => {
        const days = []
        for (let i = 0; i < showDays; i++) {
            days.push(renderDay(new Date(+monday + i * 24 * 3600 * 1000)))
        }
        return days
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Button
                    classname={styles.button}
                    onClick={() => prevMonday()}
                    label={naiveIsMobile ? '❮' : '❮ Poprzedni tydzień'}
                />
                <span>Grafik obiektu</span>
                <Button
                    classname={styles.button}
                    onClick={() => nextMonday()}
                    label={naiveIsMobile ? '❯' : 'Następny tydzień ❯'}
                />
            </div>
            <div className={styles.days}>{renderWeek(monday)}</div>
        </div>
    )
}

export default CustomCalendar
