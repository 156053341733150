import React from 'react'
import cn from 'classnames'
import Link from '../../components/Link'
import { Link as LinkDom, useLocation } from 'react-router-dom'

import styles from './NavigationItem.module.scss'

interface Item {
    label?: string
    path?: string
    onClick?: () => void
    children?: JSX.Element
    Component?: () => JSX.Element
    itemClassname?: string
    id: number
    tilesPageUrl?: string
    rows?: any
    calendar?: any
    isMenu?: boolean
    viewType?: string
}

export interface NavigationItemProps extends Item {
    subItems?: Item[]
}

const NavigationItem = (props: NavigationItemProps) => {
    const { id, label, path, subItems, onClick, children, itemClassname } = props

    const defaultYearStartFrom = 2021
    // nav: Ogłoszenia
    const yearFilterEnabled = [1009]
    // nav: Internetowy System Składania Ofert
    const staticLink: { [key: number]: string } = {
        1054: 'http://www.licytacje.bosir.bialystok.pl/',
    }

    const location = useLocation()

    const renderLastYearsFilter = (id: number) => {
        const years = []
        for (let i = new Date().getFullYear(); i >= defaultYearStartFrom; i--) {
            years.push(
                <LinkDom to={`${path}#${i}`} key={i} onClick={onClick}>
                    <div className={styles.subItem}>{i}</div>
                </LinkDom>
            )
        }

        return <div className={styles.subItemsContainer}>{years}</div>
    }

    return (
        <div
            className={cn(
                styles.container,
                location.pathname.split('/')[1] === path && styles.isSelected
            )}
        >
            <LinkDom to={path ? path : location} onClick={onClick}>
                <div className={cn(styles.item, itemClassname && itemClassname)}>
                    {label}
                    {children}
                </div>
            </LinkDom>
            {yearFilterEnabled.includes(id) && renderLastYearsFilter(id)}
            {subItems && subItems.length > 0 && (
                <div className={styles.subItemsContainer}>
                    {subItems.map((item) =>
                        staticLink[item.id] ? (
                            <Link target={'_blank'} href={staticLink[item.id]}>
                                <div className={styles.subItem}>{item.label}</div>
                            </Link>
                        ) : (
                            <LinkDom
                                to={item.path ? item.path : location}
                                key={item.label}
                                onClick={onClick}
                            >
                                <div className={styles.subItem}>{item.label}</div>
                            </LinkDom>
                        )
                    )}
                </div>
            )}
        </div>
    )
}

export default NavigationItem
