import React, { useEffect, useState } from 'react'

import TileModal from '../../components/TileModal'
import TilesRow from '../../components/TilesRow'
import { fetchCategoryById, fetchTiles } from '../../api/api'
import { Page } from '../interfaces'
import TilesPlaceholder from '../../components/TilesPlaceholder'
import { getRowsWithTiles } from '../../common/utils'
import styles from './StandardHtml.module.scss'
import Map from '../../components/Map'
import PagePlaceholder from '../PagePlaceholder'

interface StandardHtmlProps extends Page {}

const StandardHtml = (props: StandardHtmlProps) => {
    const { id, rows } = props

    const [category, setCategory] = useState<any>(null)
    const [activeTile, setActiveTile] = useState(undefined)
    const [tiles, setTiles] = useState([])
    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])
    const [noTiles, setNoTiles] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            const data = await fetchCategoryById(id)
            setCategory(data)
        })()
        ;(async () => {
            const data = await fetchTiles(id)
            const dataTiles = data
            setTiles(dataTiles)
            setRowsWithTiles(getRowsWithTiles(rows, dataTiles))
            setNoTiles(!dataTiles.length)
        })()
    }, [id])

    const tempMapNames: { [key: string]: string } = {}

    const onTileClick = (id: number) => {
        setActiveTile(tiles.find((r: any) => r.id === id))
    }

    if (!category) {
        return <PagePlaceholder />
    }

    return (
        <div className={styles.container}>
            <div className={styles.mainTitle}>
                {(tempMapNames[category.name.toLowerCase()] || category.name).toUpperCase()}
            </div>
            <section
                dangerouslySetInnerHTML={{ __html: category?.shortDescription }}
                className="mb-5 px-2"
            ></section>
            {rowsWithTiles?.length
                ? rowsWithTiles?.map((r: any, i: number) => (
                      <TilesRow key={i} row={r} onTileClick={onTileClick} />
                  ))
                : noTiles
                ? ''
                : TilesPlaceholder()}
            {activeTile && (
                <TileModal
                    isOpen={!!activeTile}
                    onClose={() => setActiveTile(undefined)}
                    tile={activeTile}
                />
            )}
            <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: category?.description }}
            />
            {category?.longitude && category?.latitude && (
                <section className="mb-3">
                    <div className={styles.contact}>
                        <span>Gdzie nas znajdziesz</span>
                    </div>
                    <Map longitude={category.longitude} latitude={category.latitude} />
                </section>
            )}
        </div>
    )
}

export default StandardHtml
