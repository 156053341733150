import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image'

import styles from './Tile.module.scss'
import cn from 'classnames'
import slugify from 'slugify'

export interface ITile {
    title: string
    image: any
    text: string
    isActive: boolean
    tileAsBanner?: boolean
    id: number
    published?: string
    isLoading?: boolean
    gallery?: any
    slug: string
}

interface TileProps {
    tile: ITile
    size: number
}

const Tile = (props: TileProps) => {
    const {
        tile: { image, title, id, slug },
        size,
    } = props
    const { src, alt } = image

    const getSlug = (slug: string): string => {
        return slug && slug[0] === '/' ? slug : `/${slug}`
    }

    const renderBanner = (): JSX.Element => (
        <Image src={src} className={styles.image} alt={alt && alt !== '1' ? alt : title} />
    )

    const renderTile = (): JSX.Element => (
        <Link
            to={!slug ? `/${slugify(title, { lower: true })}-${id}` : getSlug(slug)}
            state={{ tile: props.tile }}
        >
            <Image src={src} className={styles.image} alt={alt && alt !== '1' ? alt : title} />
            <div className={styles.title}>{title}</div>
            <div className={cn(styles.mask, 'notInvertColors')} />
        </Link>
    )

    return (
        <div className={cn(styles.container, styles[`tileSize${size}`])}>
            {props.tile?.tileAsBanner ? renderBanner() : renderTile()}
        </div>
    )
}

export default Tile
