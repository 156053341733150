import React, {useEffect, useRef, useState} from 'react'

import { baseUrl } from '../../api/api'
import styles from './Gallery.module.scss'

interface GalleryProps {
    source: any[]
    withList?: boolean
}

const Gallery = (props: GalleryProps) => {
    const { source, withList = false } = props
    const fullImageRef = useRef<HTMLDivElement>(null)

    const container = document.createElement('div')
    container.classList.add(styles.container)
    const [maxHeight, setMaxHeight] = useState<number>(0)
    const images = source.map((s, i) => {
        const img = document.createElement('img')
        img.classList.add(styles.img)
        img.src = baseUrl + s.src
        img.id = s['id']

        if (i === 0) img.style.opacity = '1'
        container?.appendChild(img)
        return img
    })

    const renderImagesList = () => {
        return source.map((source) => {
            return <img src={baseUrl + source.src} alt={source.alt} />
        })
    }

    useEffect(() => {
        let current = 0
        const id = setInterval(() => {
            images.forEach((image, index) => {
                const c = document.getElementById(source[index]['id'])

                if (c && current === index) {
                    c.style.opacity = '1'
                } else if (c) {
                    c.style.opacity = '0'
                }
            })
            current++
            if (current > source.length - 1) current = 0
        }, 3000)

        return () => clearInterval(id)
    }, [])
    return (
        <>
            <div ref={fullImageRef}
                 // style={{height: maxHeight + 'px'}}
                 dangerouslySetInnerHTML={{ __html: container?.outerHTML ?? '' }} />
            <div className={styles.imagesList}>{withList && renderImagesList()}</div>
        </>
    )
}

export default Gallery
