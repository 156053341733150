import React from "react";

import styles from "./LoadingTile.module.scss"
import Loader from "../../Loader";

const LoadingTile = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {/*<Loader />*/}
            </div>
        </div>
    )
}

export default LoadingTile;
