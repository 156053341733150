import React from 'react'

import TilesRow from '../TilesRow'

const TilesPlaceholder = () => {
    const naiveIsMobile = window.innerWidth <= 768
    const rowsToDisplay = naiveIsMobile ? 2 : 5
    const getEmptyTile = () => ({
        isLoading: true,
        title: '',
        image: '',
        text: '',
        id: 0,
    })

    const getLoadingRows = () => {
        return Array.from({ length: rowsToDisplay }).map((t, i) => ({
            tiles: Array.from({ length: i + 1 }).map(() => getEmptyTile()),
        }))
    }

    return getLoadingRows().map((r: any, i: number) => (
        <TilesRow key={i} row={r.tiles} onTileClick={() => {}} />
    ))
}

export default TilesPlaceholder
