import React from "react"
import { 
    MapContainer,
    TileLayer,
    Marker,
} from 'react-leaflet'
import { LatLngExpression } from "leaflet"

interface MapProps {
    latitude: number
    longitude: number
}
const Map = (props: MapProps) => {
    const {
        latitude,
        longitude,
    } = props
    
    const latLng: LatLngExpression = [latitude, longitude]
    
    return (
        <MapContainer center={latLng} zoom={13} scrollWheelZoom={false} style={{ height: "350px" }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={latLng}>
            </Marker>
        </MapContainer>
    )
}

export default Map