import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { fetchCategories } from './api/api'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import SocialMedia from './components/SocialMedia'
import { prepareRoutes } from './pages/routes'
import { NavigationItemProps } from './components/Navigation/NavigationItem'
import ActivityTable from './components/ActivityTable'
import Cookies from './components/Cookies'
import SearchResult from './pages/SearchResult'

import styles from './App.module.scss'
import PagePlaceholder from './pages/PagePlaceholder'
import TopButton from './components/TopButton'
import NotFound from './pages/NotFound'
import TilePage from './pages/TilePage'

export const App = ({time = 0}) => {
    const [categories, setCategories] = useState<NavigationItemProps[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!isLoading) {
            ;(async () => {
                setIsLoading(true)
                await fetchCategories()
                    .then((data) => {
                        const categoriesTree: NavigationItemProps[] = prepareRoutes(data)
                        setCategories(categoriesTree)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            })()
        }
    }, [])

    const getRoutes = (): JSX.Element[] => {
        const routes: JSX.Element[] = []
        categories?.forEach((props, i) => {
            const { path, Component, subItems, label, id, rows, calendar } = props
            routes.push(
                <Route
                    key={`${label}${path}`}
                    path={path ?? '/'}
                    // @ts-ignore
                    element={Component ? <Component id={id} rows={rows} calendar={calendar} /> : () => {}}
                />
            )
            routes.push(
                <Route
                    key={i}
                    path={`${path}/:id`}
                    element={<TilePage path={path}/>}
                />
            )
            routes.push(<Route key={i} path={`${path}/:id`} element={<TilePage path={path} />} />)
            subItems?.forEach((props, i) => {
                const { path, Component, id, rows, calendar } = props
                routes.push(
                    <Route
                        key={`${label}${path}`}
                        path={path ?? '/'}
                        // @ts-ignore
                        element={Component ? <Component id={id} rows={rows} calendar={calendar} /> : () => {}}
                    />
                )
                routes.push(
                    <Route key={i} path={`${path}/:id`} element={<TilePage path={path} />} />
                )
            })
        })

        return routes
    }

    return (
        <>
            <BrowserRouter>
                <div className={styles.app}>
                    <Navigation categories={categories} />
                    <div className={styles.contentContainer}>
                        {categories.length ? (
                            <Routes>
                                {getRoutes()}
                                <Route path="wyszukaj" element={<SearchResult />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        ) : (
                            <PagePlaceholder />
                        )}
                    </div>
                    <Footer />
                </div>
            </BrowserRouter>
            <Cookies />
            <SocialMedia />
            <ActivityTable />
            <TopButton />
        </>
    )
}

export default App
