import React, { useEffect, useState } from 'react'

import DatePicker from '../../components/DatePicker'
import { fetchCategoryById, fetchTiles, getAvailableRooms } from '../../api/api'
import RoomList from '../../components/RoomList'
import RoomModal from '../../components/RoomModal'
import Button from '../../components/Button'
import styles from './Hotel.module.scss'
import classNames from 'classnames'
import moment from 'moment'
import Loader from '../../components/Loader'
import Map from '../../components/Map'
import TilesRow from '../../components/TilesRow'
import { getRowsWithTiles } from '../../common/utils'
import TilesPlaceholder from '../../components/TilesPlaceholder'
import PagePlaceholder from '../PagePlaceholder'

const Hotel = (props: any) => {
    const { id } = props
    const [selectedDates, setSelectedDates] = useState<Date[] | undefined>(undefined)
    const [availableRooms, setAvailableRooms] = useState<any[] | undefined>(undefined)
    const [selectedRoom, setSelectedRoom] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [categoryData, setCategoryData] = useState<any>(null)
    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])

    const onChangeRange = (dates: Date[]) => {
        setSelectedDates(dates)
    }

    useEffect(() => {
        ;(async () => {
            const data = await fetchCategoryById(id)
            setCategoryData(data)
        })()
    }, [id])

    useEffect(() => {
        if (categoryData) {
            ;(async () => {
                const tilesData = await fetchTiles(id)
                setRowsWithTiles(getRowsWithTiles(categoryData.rows, tilesData))
            })()
        }
    }, [categoryData])

    const checkAvailableRooms = async () => {
        if (selectedDates?.[0] && selectedDates?.[1]) {
            setIsLoading(true)

            const dates = selectedDates.map((d) => {
                const day = d?.getDate() < 10 ? '0' + d?.getDate() : d?.getDate()
                const actualMonth = d?.getMonth() + 1
                const month = actualMonth < 10 ? '0' + actualMonth : actualMonth

                return `${d?.getFullYear()}-${month}-${day}`
            })

            const rooms = await getAvailableRooms(dates[0], dates[1])
            setAvailableRooms(rooms)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        checkAvailableRooms()
    }, [selectedDates])

    const tempMapNames: { [key: string]: string } = {}

    return categoryData ? (
        <div>
            {categoryData.mainImagePath?.src ? (
                <div className={styles.mainImageContainer}>
                    <div
                        className={styles.mainImage}
                        style={{
                            backgroundImage: `url('https://miejskoaktywni.pl${categoryData.mainImagePath.src}`,
                        }}
                    ></div>
                    <div className={styles.mainTitle}>
                        {(
                            tempMapNames[categoryData.name.toLowerCase()] || categoryData.name
                        ).toUpperCase()}
                    </div>
                </div>
            ) : (
                <div className={styles.mainTitle}>
                    {(
                        tempMapNames[categoryData.name.toLowerCase()] || categoryData.name
                    ).toUpperCase()}
                </div>
            )}

            <section dangerouslySetInnerHTML={{ __html: categoryData?.shortDescription }}></section>
            {/*<div className={styles.container}>*/}
            {/*    <RoomModal*/}
            {/*        isOpen={!!selectedRoom}*/}
            {/*        onClose={() => setSelectedRoom(undefined)}*/}
            {/*        room={selectedRoom}*/}
            {/*        startDate={selectedDates?.[0]}*/}
            {/*        endDate={selectedDates?.[1]}*/}
            {/*    />*/}
            {/*    <div className={styles.leftColumn}>*/}
            {/*        <DatePicker*/}
            {/*            onChangeRange={onChangeRange}*/}
            {/*            selectsRange*/}
            {/*            startDate={selectedDates?.[0]}*/}
            {/*            endDate={selectedDates?.[1]}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className={styles.rightColumn}>*/}
            {/*        {selectedDates ? (*/}
            {/*            <div className={styles.selectedDates}>*/}
            {/*                Pokoje dostępne w okresie od{' '}*/}
            {/*                <strong>{moment(selectedDates?.[0]).format('DD-MM-YYYY')}</strong> do{' '}*/}
            {/*                <strong>*/}
            {/*                    {selectedDates?.[1]*/}
            {/*                        ? moment(selectedDates?.[1]).format('DD-MM-YYYY')*/}
            {/*                        : '...'}*/}
            {/*                </strong>*/}
            {/*            </div>*/}
            {/*        ) : (*/}
            {/*            ''*/}
            {/*        )}*/}
            {/*        {isLoading ? (*/}
            {/*            <div className={styles.roomLoader}>*/}
            {/*                <Loader type={1} />*/}
            {/*            </div>*/}
            {/*        ) : (*/}
            {/*            <RoomList*/}
            {/*                rooms={availableRooms}*/}
            {/*                setSelectedRoom={setSelectedRoom}*/}
            {/*                containerClassname={classNames(*/}
            {/*                    !availableRooms?.length && styles.roomListContainer*/}
            {/*                )}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <section>
                {rowsWithTiles?.length
                    ? rowsWithTiles.map((r: any, i: number) => <TilesRow key={i} row={r} />)
                    : TilesPlaceholder()}
            </section>
            <section
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: categoryData?.description }}
            ></section>
            {categoryData?.longitude && categoryData?.latitude && (
                <div>
                    <section>
                        <div className={styles.contact}>
                            <span>Gdzie nas znajdziesz</span>
                        </div>
                        <Map longitude={categoryData.longitude} latitude={categoryData.latitude} />
                    </section>
                </div>
            )}
        </div>
    ) : (
        <PagePlaceholder />
    )
}

export default Hotel
