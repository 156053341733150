import React, { ChangeEvent } from "react";
import classNames from "classnames";

import styles from "./Input.module.scss"

interface InputProps {
    placeholder?: string
    value?: string | number
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    type?: string
    step?: number
    classname?: string
    label?: string
    isRequired?: boolean
    labelClassname?: string
    labelPosition?: 'left' | 'right'
    containerClassname?: string
    forId?: string
    isChecked?: boolean
    size?: number
    min?: number
    max?: number
    maxlength?: number
}

const Input = (props: InputProps) => {
    const {
        placeholder,
        type = "text",
        value,
        step,
        onChange,
        size,
        min,
        max,
        classname,
        label,
        isRequired = false,
        labelClassname,
        labelPosition,
        containerClassname,
        isChecked,
        maxlength
    } = props

    const input = (
        <input
            className={classNames(styles.input, classname)}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            min={min}
            max={max}
            step={step}
            size={size}
            required={isRequired}
            checked={isChecked}
            maxLength={maxlength}
        />
    )

    return (
        <div className={classNames(
            styles.container,
            containerClassname,
        )}
        >
            {label ? (
                <label className={classNames(
                    styles.label,
                    labelClassname,
                    label && labelPosition === 'left' && styles.invert,
                )}
                >
                    {label}{input}
                </label>
            ) : (
                input
            )}
        </div>
    )
}

export default Input
