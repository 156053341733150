import React, { useEffect, useState } from 'react'

import { fetchActivitiesTable } from '../../api/api'

import styles from './ActivityTable.module.scss'

const ActivityTable = () => {
    const [activities, setActivities] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchActivitiesTable()
            const parsedData = data?.map((m: any) => ({
                title: m.title,
                content: m.content,
            }))

            setActivities(parsedData)
        }

        fetchData()
    }, [])

    const formatUsersCount = (content: string): string => {
        const chunks = content.split(':')
        if (chunks.length === 2) {
            return `${chunks[0]}: <span>${chunks[1]}</span>`
        }

        return content
    }

    return (
        <div className={styles.container}>
            <div className={styles.table}>
                {activities?.map(({ title, content }) => (
                    <div key={title}>
                        <div className={styles.facilityName}>{title}</div>
                        <div className={styles.usersCount} dangerouslySetInnerHTML={{__html: formatUsersCount(content)}}></div>
                    </div>
                ))}
            </div>
            <div className={styles.label}>Liczba osób w obiektach</div>
        </div>
    )
}

export default ActivityTable
