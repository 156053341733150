import React from 'react'
import styles from './EmptyListNotification.module.scss'

interface EmptyListNotificationProps {
    message?: string
}

const EmptyListNotification = ({ message = '' }: EmptyListNotificationProps) => (
    <div className={styles.header}>
        Jeszcze pracujemy nad tą sekcją <span className={styles.icon}>&#128395;</span>
    </div>
)

export default EmptyListNotification
