import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookies = () => (
    <CookieConsent
        buttonText="Akceptuj"
        cookieName="cookiesAccepted"
        style={{ background: "#2B373B", alignItems: "center", zIndex: 1001 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", fontWeight: "bold" }}
    >
        Strona internetowa <strong>miejskoaktywni.pl</strong> używa cookies m.in. w celach: korzystania z witryny, usprawnienia
        procesu zapamiętywaniu ustawień. Korzystanie z witryny bez zmiany ustawień Twojej przeglądarki oznacza,
        że będą one umieszczane w Twojej przeglądarce. Pamiętaj, że zawsze możesz zmienić te ustawienia.{" "}
    </CookieConsent>
)

export default Cookies;
