import React from "react"
import classNames from "classnames"

import styles from "./Button.module.scss"

interface ButtonProps {
    label: string
    onClick?: (e: any) => void
    classname?: string
    isDisabled?: boolean
    type?: "button" | "submit" | "reset" | undefined
    onSubmit?: (e: any) => void
    name?: string
}

const Button = (props: ButtonProps) => {
    const {
        label,
        classname,
        name,
        isDisabled,
        onClick,
        type = 'button',
        onSubmit,
    } = props
    
    return (
        <button 
            className={classNames(styles.button, classname)} 
            disabled={isDisabled}
            name={name}
            onClick={onClick}
            type={type}
            onSubmit={onSubmit}
        >
            {label}
        </button>
    )
}

export default Button
