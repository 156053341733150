import React, { useEffect, useState } from 'react'

import Image from '../Image'
import cn from 'classnames'
import styles from './Navigation.module.scss'

const FontSizeButton = () => {
    const [fontSize, setFontSize] = useState<number>(0)

    const changeFontSize = (forceSize?: number) => {
        const size = ['font-100', 'font-120', 'font-140']
        let newSize: number = fontSize || 0
        newSize += 1
        if (newSize >= size.length) {
            newSize = 0
        }
        setFontSize(newSize)
        // @ts-ignore
        document.querySelectorAll('#root').forEach((e) => {
            e.classList.remove('font-100')
            e.classList.remove('font-120')
            e.classList.remove('font-140')
            e.classList.add(size[newSize])
        })
        window.localStorage.setItem('miejskoaktywni-font-size', '' + newSize)
    }

    useEffect(() => {
        const fontS = window.localStorage.getItem('miejskoaktywni-font-size')
        if (fontS) {
            changeFontSize(+fontS - 1)
        }
    }, [])

    return (
        <span onClick={() => changeFontSize()} className={cn(styles.fontSize, 'notInvertColors')} title="Zmień rozmiar czcionki">
            <img src={'/font-size.png'} alt={'Zmień rozmiar czcionki'} />
        </span>
    )
}

export default FontSizeButton
