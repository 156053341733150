import React from "react";
// @ts-ignore
import ReactDatePicker from 'react-datepicker'
import classNames from "classnames"

import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import DatepickerDay from "./DatepickerDay";

interface DatePickerProps {
    selected?: Date
    onChange?: (date: Date) => void
    onChangeRange?: (dates: Date[]) => void
    minDate?: Date
    dateFormat?: string
    locale?: any
    numberOfMonths?: number
    monthsShown?: number
    showWeekNumber?: boolean
    inline?: boolean
    calendarStartDay?: number
    startDate?: Date
    endDate?: Date
    selectsRange?: boolean
    containerClassname?: string
    highlightDates?: Date[]
    onDayMouseOver?: (data: any) => void
    onDayMouseOut?: () => void
}

const days = ['Nie', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So']
const months = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
]

export const defaultLocale = {
    locale: 'pl',
    localize: {
        day: (n: number) => days[n],
        month: (n: number) => months[n],
    },
    firstDay: 1,
    formatLong: {
        date: () => 'dd/MM/yyyy',
    },
}

const DatePicker = (props: DatePickerProps) => {
    const {
        selected,
        onChange,
        minDate = new Date(),
        dateFormat = "dd/MM/yyyy",
        locale = defaultLocale,
        numberOfMonths = 0.5,
        monthsShown = 0.5,
        showWeekNumber = true,
        inline = true,
        calendarStartDay = 1,
        selectsRange,
        startDate,
        endDate,
        onChangeRange,
        containerClassname,
        highlightDates,
        onDayMouseOver = () => {},
        onDayMouseOut = () => {},
    } = props

    return (
        <div className={classNames(containerClassname)}>
            <ReactDatePicker
                selected={selected}
                onChange={selectsRange ? onChangeRange : onChange}
                minDate={minDate}
                dateFormat={dateFormat}
                locale={locale}
                calendarStartDay={calendarStartDay}
                renderDayContents={(day:any, date:any) => <DatepickerDay onMouseOver={onDayMouseOver} onMouseOut={onDayMouseOut} day={day} date={date} />}
                numberOfMonths={numberOfMonths}
                monthsShown={monthsShown}
                showWeekNumber={showWeekNumber}
                inline={inline}
                selectsRange={selectsRange}
                startDate={startDate}
                endDate={endDate}
                highlightDates={highlightDates}
            />
        </div>
    )
}

export default DatePicker
