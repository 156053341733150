import React, { MouseEventHandler } from "react";
import styles from "./Navigation.module.scss";
import cn from "classnames";

interface BurgerProps {
    toggleMenu: MouseEventHandler<HTMLDivElement>
}

const Burger = (props: BurgerProps) => {
    return (
        <div className={styles.mobileMenu} onClick={props.toggleMenu}>
            <div className={cn(styles.line, 'notInvertColors')} />
            <div className={cn(styles.line, 'notInvertColors')} />
            <div className={cn(styles.line, 'notInvertColors')} />
        </div>
    )
}

export default Burger;
