import React, { useEffect, useState } from 'react'

import TileModal from '../../components/TileModal'
import EmptyListNotification from '../../components/EmptyListNotification'
import TilesRow from '../../components/TilesRow'
import { fetchTiles } from '../../api/api'
import { Page } from '../interfaces'
import TilesPlaceholder from '../../components/TilesPlaceholder'
import { getRowsWithTiles } from '../../common/utils'
import { useLocation } from 'react-router-dom'

interface StandardProps extends Page {}

const Standard = (props: StandardProps) => {
    const { id, rows } = props

    const location = useLocation()

    const [activeTile, setActiveTile] = useState(undefined)
    const [tiles, setTiles] = useState([])
    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])
    const [noTiles, setNoTiles] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            const yearFilter = getYearFilter()
            let filters = yearFilter
                ? [
                      `published[before]=${yearFilter + 1}-01-01`,
                      `published[after]=${yearFilter}-01-01`,
                  ]
                : null

            const dataTiles = await fetchTiles(id, 1, filters)
            setTiles(dataTiles)
            setRowsWithTiles(getRowsWithTiles(rows, dataTiles))
            setNoTiles(!dataTiles.length)
        })()
    }, [id, location.hash])

    const getYearFilter = () => {
        if (location.hash) {
            const hash = +location.hash.replace('#', '')

            /** verify year from hash **/
            if (!isNaN(hash) && hash > 2000 && hash < 3000) {
                return hash
            }
        }

        return null
    }

    const onTileClick = (id: number) => {
        setActiveTile(tiles.find((r: any) => r.id === id))
    }

    return (
        <div>
            {rowsWithTiles?.length
                ? rowsWithTiles?.map((r: any, i: number) => (
                      <TilesRow key={i} row={r} onTileClick={onTileClick} />
                  ))
                : noTiles
                ? <EmptyListNotification />
                : TilesPlaceholder()}
            {activeTile && (
                <TileModal
                    isOpen={!!activeTile}
                    onClose={() => setActiveTile(undefined)}
                    tile={activeTile}
                />
            )}
        </div>
    )
}

export default Standard
