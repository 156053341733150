import React from 'react'

import styles from '../TrackModal/BookTrackForm.module.scss'
import Button from '../Button'

interface ErrorInfoProps {
    error?: string
}

const ErrorInfo = ({ error = '' }: ErrorInfoProps) => (
    <div className={styles.error}>
        Ups... Coś poszło nie tak, odśwież i spróbuj jeszcze raz. {error}
        <div>
            <Button label="Odśwież" onClick={() => window.location.reload()} />
        </div>
    </div>
)

export default ErrorInfo
