import React from "react"

import Input from "../Input"
import styles from "./InvoiceForm.module.scss"

interface InvoiceFormProps {
    handleChange: (val: string, label: string) => void
    data: any
}

const InvoiceForm = (props: InvoiceFormProps) => {
    const {
        handleChange,
        data,
    } = props

    return (
        <div className={styles.container}>
            <Input 
                placeholder="Nazwa firmy / Imię nazwisko"
                value={data.companyName}
                size={50}
                onChange={(e) => handleChange(e.target.value, 'companyName')}
            />
            <Input
                placeholder="Ulica"
                size={37}
                value={data.street}
                onChange={(e) => handleChange(e.target.value, 'street')}
            />
            <Input
                placeholder="Numer"
                size={4}
                value={data.localNumber}
                onChange={(e) => handleChange(e.target.value, 'localNumber')}
            />
            <Input
                placeholder="Miejscowość"
                value={data.city}
                onChange={(e) => handleChange(e.target.value, 'city')}
            />
            <Input
                placeholder="00-000"
                size={8}
                value={data.postCode}
                onChange={(e) => handleChange(e.target.value, 'postCode')}
            />
            <Input
                placeholder="NIP"
                value={data.nip}
                maxlength={10}
                onChange={(e) => handleChange(e.target.value, 'nip')}
            />
        </div>
    )
}

export default InvoiceForm
