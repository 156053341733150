import Carousel from 'react-bootstrap/Carousel'
import { baseUrl } from '../../api/api'

import 'bootstrap/dist/css/bootstrap.min.css'
import cn from 'classnames'
import './styles.css'

interface CustomCarouselProps {
    tile: any
}

const CustomCarousel = (props: CustomCarouselProps) => {
    const { tile } = props

    if (!tile.gallery || !Object.values(tile.gallery)) {
        return null
    }
    return (
        <Carousel fade>
            {Object.values(tile.gallery).map((image: any) => (
                <Carousel.Item>
                    <img
                        className={cn('d-block w-100')}
                        src={baseUrl + image.src}
                        alt={image.alt}
                    />

                    <Carousel.Caption>
                        <h3></h3>
                        <p></p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default CustomCarousel
