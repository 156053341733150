import React from 'react'

import styles from './Loader.module.scss'

interface LoaderProps {
    type?: number
}

const Loader = (props: LoaderProps) => {
    const { type } = props
    if (type === 1) {
        return (
            <div className={styles.ldsRipple}>
                <div></div>
                <div></div>
            </div>
        )
    }
    return (
        <div className={styles.ldsEllipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loader
