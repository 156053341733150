// see https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
import React, { useState, useRef, useEffect } from "react";

export const useStateRef = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);

    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
}