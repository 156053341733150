import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import styles from './TilePage.module.scss'

import { baseUrl, fetchOldTilesById, fetchTileById } from '../../api/api'
import moment from 'moment'
import { LANG_MONTHS_PL_CASE } from '../../components/Calendar/calendar.types'
import Loader from '../../components/Loader'
import NotFound from '../NotFound'
import CustomCarousel from '../../components/CustomCarousel'
import { createPrintHtml, openPrintDialog } from '../../common/print'
import Image from '../../components/Image'
import CheckOtherSites from '../CheckOtherSites'

interface TilePageProps {
    path?: string
}

const TilePage = (props: TilePageProps) => {
    const location = useLocation()
    // @ts-ignore
    const [tile, setTile] = useState<any>(location.state?.tile)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        setIsLoading(true)
        const fetchTile = async () => {
            const urlArr = window.location.pathname.split('-')
            const isOld = window.location.pathname.includes(',') || window.location.pathname.includes('#')
            const id = +urlArr[urlArr.length - 1]

            if (id) {
                if (isOld) {
                    const tiles = await fetchOldTilesById(id)
                    if (tiles && tiles.length === 1) {
                        setTile(tiles[0])
                    }
                } else {
                    const tile = await fetchTileById(id)
                    setTile(tile['id'] ? tile : null)
                    checkRedirect()
                }
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }

        fetchTile()
    }, [location.pathname])

    const checkRedirect = () => {
        if (tile && tile.text && tile.text.indexOf('redirectTo') !== -1) {
            const match = tile.text.match('redirectTo=["\'](.+)["\']')
            if (match && match.length) {
                window.location.href = match[1]
            }
        }
    }

    const renderDate = (date: string) => {
        const [day, month, year] = moment(date).format('DD MM YYYY').split(' ')
        return (
            <div className={styles.date} title={'Opublikowano'}>
                <div className={styles.day}>{day}</div>
                {LANG_MONTHS_PL_CASE[+month - 1]} {year}
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {isLoading ? (
                <div className={styles.loader}>
                    <Loader type={1} />
                </div>
            ) : tile ? (
                <>
                    <div className={styles.header}>
                        <Image
                            src="/printer.svg"
                            onClick={() => {
                                const html = createPrintHtml(tile.title, tile.text)
                                openPrintDialog(html)
                            }}
                            className={styles.printButton}
                        />
                        <div className={styles.title}>{tile.title}</div>
                        <div>{tile.published && renderDate(tile.published)}</div>
                    </div>
                    {tile.mainImage && (
                        <div className={styles.mainImage}>
                            <img
                                src={baseUrl + tile.mainImage.src}
                                alt={tile.mainImage.alt}
                                className={'img-fluid'}
                            />
                        </div>
                    )}
                    <div className={styles.gallery}>
                        <CustomCarousel tile={tile} />
                    </div>
                    <div
                        className={styles.content}
                        dangerouslySetInnerHTML={{ __html: tile.text }}
                    ></div>
                    {tile.relatedTiles && tile.relatedTiles.length ? (
                        <CheckOtherSites header={'Polecane artykuły'} tiles={tile.relatedTiles} />
                    ) : null}
                </>
            ) : (
                <NotFound />
            )}
        </div>
    )
}

export default TilePage
