import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { searchTiles } from '../../api/api'
import TilesRow from '../../components/TilesRow'
import TileModal from '../../components/TileModal'
import styles from './SearchResult.module.scss'
import CheckOtherSites from '../CheckOtherSites'

const SearchResult = () => {
    // const {query} = useParams();
    const query = document.location?.search.replace('?', '')
    const [rows, setRows] = useState<any>([])
    const [nothingFound, setNothingFound] = useState<boolean>(false)
    const [emptyQuery, setEmptyQuery] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            if (query && query !== '') {
                setEmptyQuery(false)
                const data = await searchTiles(query)
                if (data) {
                    setNothingFound(false)
                    const splitTiles = data
                        .reduce((acc: any, item: any, index: number) => {
                            const chunkIndex = Math.floor(index / 3)

                            if (!acc[chunkIndex]) {
                                acc[chunkIndex] = [] // start a new chunk
                            }

                            acc[chunkIndex].push(item)

                            return acc
                        }, [])
                        .map((r: any) => ({ tiles: r }))
                    setRows(splitTiles)
                } else {
                    setNothingFound(true)
                }
            } else {
                setEmptyQuery(true)
            }
        }
        fetchData()
    }, [])

    const renderNothingFound = () => {
        return (
            <>
                <p className={styles.notFound}>Nie znaleziono wyników dla frazy: {query}</p>
                <CheckOtherSites id={1011} />
            </>
        )
    }

    const renderEmptyQuery = () => {
        return ''
    }

    return (
        <div className={styles.container}>
            {nothingFound && renderNothingFound()}
            {emptyQuery && renderEmptyQuery()}

            {rows?.map((r: any, i: number) => (
                <TilesRow key={i} row={r.tiles} onTileClick={() => {}} />
            ))}
        </div>
    )
}

export default SearchResult
