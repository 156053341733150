interface DayProps {
    day: string | number,
    date: any
    onMouseOver: (data: any) => void
    onMouseOut: () => void
}

const DatepickerDay = (props: DayProps) => {
    return <div onMouseOut={() => props.onMouseOut()} onMouseOver={() => props.onMouseOver(props.date)} style={{padding: '0.6rem', borderRadius: '0.4rem'}}>{props.day}</div>
}

export default DatepickerDay
