import React, { useRef, useEffect, useState } from 'react'

import InvoiceForm from '../InvoiceForm'
import Input from '../Input'
import Button from '../Button'
import { useStateRef } from '../../hooks/useStateRef'
import { createScheduleReservation } from '../../api/api'
import styles from './BookTrackForm.module.scss'
import CustomCheckbox from '../CustomCheckbox'
import ErrorInfo from "../ErrorInfo"
import {ISchedule} from "tui-calendar";

const defaultData = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    amount: 1,
    isFv: false,
    companyName: '',
    street: '',
    localNumber: '',
    postCode: '',
    city: '',
    nip: '',
}

interface BookTrackFormProps {
    price: number
    scheduleId: string
    schedule: any
}

const checkboxLabels = [
    'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu realizacji zamówienia',
    'Wyrażam zgodę na dostarczenie treści cyfrowych w okolicznościach powodujących utratę prawa odstąpienia od umowy',
    'Oświadczam, że będąc świadomy/a utraty prawa do odstąpienia od umowy chcę aby usługa była zrealizowana przed upływem terminu na odstąpienie od umowy, tj. przed upływem 14 dni od zawarcia umowy',
    // TODO: add link
    'Akceptuję regulamin',
]

const BookTrackForm = (props: BookTrackFormProps) => {
    const { price, scheduleId, schedule } = props

    const formRef = useRef<HTMLFormElement>(null)
    const [data, setData, ref] = useStateRef(defaultData)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (val: string | boolean, label: string) => {
        setData({ ...data, [label]: val })
    }

    const onSubmit = async (e: any) => {
        e.preventDefault()
        setIsLoading(true)

        const requestPayload = {
            schedule: '/api/schedules/' + scheduleId,
            name: ref.current.name,
            surname: ref.current.surname,
            email: ref.current.email,
            phone: ref.current.phone,
            agreeData: true,
            agreeRegulations: true,
            isFv: ref.current.isFv,
            amount: +ref.current.amount,
            continueUrl: 'https://miejskoaktywni.pl/tor?potwierdzenie',
        }

        if (ref.current.isFv) {
            const invoicePayload = {
                companyName: ref.current.companyName,
                street: ref.current.street,
                localNumber: ref.current.localNumber,
                postCode: ref.current.postCode,
                city: ref.current.city,
                nip: ref.current.nip,
            }

            const res = await createScheduleReservation({ ...requestPayload, ...invoicePayload })

            if (res.redirectUrl) {
                window.location.href = res.redirectUrl
            } else {
                console.warn('Error: no redirect url1')
                setError(true)
            }
        } else {
            const res = await createScheduleReservation(requestPayload)

            if (res.redirectUrl) {
                window.location.href = res.redirectUrl
            } else {
                console.warn('Error: no redirect url2')
                setError(true)
            }
        }
        setIsLoading(false)
    }
    useEffect(() => {
        formRef.current?.addEventListener('submit', onSubmit)

        return () => formRef.current?.removeEventListener('submit', onSubmit)
    }, [formRef])

    return (
        !error ? (
            <form ref={formRef}>
                <div className={styles.row}>
                    {/*<div className={styles.column}>*/}
                    <Input
                        placeholder="Imię"
                        value={data.name}
                        onChange={(e) => handleChange(e.target.value, 'name')}
                        isRequired={true}
                        size={25}
                    />
                    <Input
                        placeholder="Nazwisko"
                        value={data.surname}
                        onChange={(e) => handleChange(e.target.value, 'surname')}
                        isRequired={true}
                        size={25}
                    />
                    <Input
                        placeholder="Email"
                        value={data.email}
                        size={38}
                        type="email"
                        onChange={(e) => handleChange(e.target.value, 'email')}
                        isRequired={true}
                    />
                    <Input
                        placeholder="Telefon"
                        value={data.phone}
                        onChange={(e) => handleChange(e.target.value, 'phone')}
                        isRequired={true}
                        size={12}
                    />
                    <Input
                        type="number"
                        min={1}
                        max={schedule?.seatsTaken - schedule?.reservedSeats || 100}
                        step={1}
                        value={data.amount}
                        label="Liczba miejsc:"
                        size={4}
                        maxlength={2}
                        onChange={(e) => handleChange(e.target.value, 'amount')}
                    />
                    <div style={{ width: '100%' }}></div>
                    <div className={styles.totalCost}>
                        <h3>Cena: {((price * data.amount) / 100).toFixed(2)} PLN</h3>
                    </div>
                </div>
                {checkboxLabels.map((label, i) => (
                    <CustomCheckbox
                        key={i}
                        value={1}
                        name={label}
                        label={label}
                        isRequired={true}
                        classname={styles.checkbox}
                    />
                ))}
                <CustomCheckbox
                    value={1}
                    name={'isFv'}
                    checked={data.isFv}
                    label={'Chcę otrzymać fakturę VAT'}
                    isRequired={false}
                    classname={styles.checkbox}
                    onChange={(e) => handleChange(e.target.checked, 'isFv')}
                />
                {data.isFv && <InvoiceForm handleChange={handleChange} data={data} />}

                <div className={styles.submitButtonContainer}>
                    <Button 
                        type="submit" 
                        label="DALEJ" 
                        classname={styles.button}
                        isDisabled={isLoading}
                    />
                </div>
            </form>
        ) : (
            <ErrorInfo />
        )
    )
}

export default BookTrackForm
