import React, {useRef, useState} from "react";
import cn from "classnames";

import Image from "../Image";
import styles from "./Search.module.scss";

const Search = () => {
    const [searchText, setSearchText] = useState<string>("")
    const [showInput, setShowInput] = useState<boolean>(false)
    const searchRef = useRef<HTMLInputElement>(null)

    const toggleInputVisibility = () => {
        setShowInput(!showInput)
        searchRef?.current?.focus()
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter' || e.keyCode === 13) {
            onSubmit()
        }
    }

    const onSubmit = () => {
        if (searchText.length) {
            window.location.href = `/wyszukaj?${encodeURIComponent(searchText)}`
        }
    }

    return (
        <div className={styles.searchContainer}>
            <div onClick={toggleInputVisibility}>
                <Image
                    src="/search.png"
                    className={styles.searchIcon}
                />
            </div>
            <div className={cn(styles.inputContainer, showInput && styles.show)}>
                <input
                    ref={searchRef}
                    className={styles.input}
                    type="text"
                    onKeyDown={onKeyPress}
                    onChange={onChange}
                    placeholder="Wpisz szukaną frazę..."
                />

                <Image
                    src="/search.png"
                    className={styles.insideSearch}
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default Search;
