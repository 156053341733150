import React from 'react'

import { Link } from 'react-router-dom'
import styles from "./Rodo.module.scss"
import Image from "../Image";

const RodoButton = () => {
    return (
        <Link className={styles.linkWithIcon} to="/polityka-prywatnosci-bosir-1023">
            <span><Image src="/shield.svg" /></span>
            <span>RODO</span>
        </Link>
    )
}

export default RodoButton
