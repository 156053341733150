import React, { useEffect, useState } from 'react'

import TilesRow from '../../components/TilesRow'
import { fetchTiles } from '../../api/api'
import TilesPlaceholder from '../../components/TilesPlaceholder'
import { getRowsWithTiles } from '../../common/utils'
import { ITile } from '../../components/Tile'

interface CheckOtherSitesProps {
    id?: number
    rows?: any
    tiles?: ITile[]
    header?: string
}

const CheckOtherSites = (props: CheckOtherSitesProps) => {
    const { rows, tiles: parentTiles, id, header } = props
    const CHECK_OTHER_SITES_ID = id || 1011
    const [tiles, setTiles] = useState<ITile[]>(parentTiles || [])
    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])
    const [noTiles, setNoTiles] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            if (!tiles || !tiles.length) {
                const data = await fetchTiles(CHECK_OTHER_SITES_ID)
                const dataTiles = data.length > 6 ? data.slice(0, 6) : data
                setTiles(dataTiles)
                setRowsWithTiles(getRowsWithTiles(rows, dataTiles))
                setNoTiles(!dataTiles.length)
            } else {
                setRowsWithTiles(getRowsWithTiles(rows, tiles))
                setNoTiles(!tiles.length)
            }
        })()
    }, [])

    return (
        <div>
            <h3 className={'mt-4'}>{header || 'Może zainteresuje Cię...'}</h3>
            {rowsWithTiles?.length
                ? noTiles
                    ? ''
                    : rowsWithTiles?.map((r: any, i: number) => <TilesRow key={i} row={r} />)
                : TilesPlaceholder()}
        </div>
    )
}

export default CheckOtherSites
