import React, {useEffect, useMemo, useState} from 'react'
import Modal from 'react-modal'

import TrackEvent from './TrackEvent'
import BookTrackForm from './BookTrackForm'
import Image from '../Image'
import styles from './TrackModal.module.scss'

interface TrackModalProps {
    isOpen: boolean
    onClose: () => void
    events?: any
}

const TrackModal = (props: TrackModalProps) => {
    const { isOpen, onClose, events } = props

    const modalStyles = useMemo(() => {
        return {
            content: {
                width: '85%',
                margin: 'auto',
                maxWidth: '800px',
                // marginTop: '45px',
                overflow: 'hidden',
                height: 'fit-content',
                transition: 'all 2s ease-in-out',
                padding: 0,
                marginTop: '3rem',
                // opacity: isOpen ? 1 : 0,
                // ...(isOpen ? { marginTop: '-20rem', } : { marginTop: '3rem', }),
            },
        }
    }, [isOpen])

    const [selectedEvent, setSelectedEvent] = useState(undefined)

    const handleClose = () => {
        onClose()
        setSelectedEvent(undefined)
    }

    const content = (
        <>
            <div className={styles.header}>
                <p className={styles.title}>
                    {selectedEvent ? 'Uzupełnij dane' : 'Wybierz wydarzenie'}
                </p>
                <div className={styles.actionButtons}>
                    <Image src="/close.svg" onClick={handleClose} className={styles.closeButton} />
                </div>
            </div>
            <div className={styles.content}>
                {selectedEvent ? (
                    <div className={styles.form}>
                        <BookTrackForm
                            // @ts-ignore
                            price={selectedEvent.price}
                            scheduleId={selectedEvent['id']}
                            schedule={selectedEvent}
                        />
                    </div>
                ) : (
                    events?.map((e: any, i: number) => (
                        <TrackEvent key={i} event={e} setSelectedEvent={setSelectedEvent} />
                    ))
                )}
            </div>
        </>
    )

    return (
        <Modal isOpen={isOpen} style={modalStyles} ariaHideApp={false}>
            {content}
        </Modal>
    )
}

export default TrackModal
