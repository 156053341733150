import React from "react";

import Loader from "../../components/Loader";
import styles from './PagePlaceholder.module.scss'

const PagePlaceholder = () => {
    return (
        <div className={styles.pagePlaceholder}>
            <Loader type={1} />
        </div>
    )
}

export default PagePlaceholder;
