import React, { useState } from 'react'
import classNames from 'classnames'

import styles from './TopButton.module.scss'

const TopButton = () => {
    const hopToTop = () => window.scrollTo(0, 0)
    const [isOnTop, setIsOnTop] = useState<boolean>(true)

    window.addEventListener('scroll', () => { setIsOnTop(window.scrollY < 300); });

    return (
        <button
            className={classNames(styles.button, isOnTop ? styles.buttonHidden : null)}
            onClick={hopToTop}
        >
            &uarr;
        </button>
    )
}

export default TopButton
