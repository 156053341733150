import React, { useEffect, useState } from 'react'

import styles from '../../App.module.scss'
import { fetchFooter } from '../../api/api'

const Footer = () => {
    const defaultFooter = '<a href="http://bialystok.pl"><img alt="Białystok" src="https://miejskoaktywni.pl/uploads/bialystok.png" /></a>';
    const [footer, setFooter] = useState<string>(defaultFooter)

    useEffect(() => {
        ;(async () => {
            const data = await fetchFooter()
            setFooter(data[0].content)
        })()
    }, [])

    return <footer dangerouslySetInnerHTML={{ __html: footer }} className={styles.footer} />
}

export default Footer
