import React, { useEffect, useState } from 'react'

import Image from '../Image'
import styles from './DayNightButton.module.scss'

const DayNightButton = () => {
    const LOCALSTORAGE_KEY_NAME = 'miejskoaktywni-colors-invert'
    const [isNightModeOn, setIsNightModeOn] = useState<boolean>(
        !!window.localStorage.getItem(LOCALSTORAGE_KEY_NAME)
    )

    useEffect(() => {
        toggleInvertColors(isNightModeOn)
    }, [])

    const toggleInvertColors = (setMode: boolean | null = null) => {
        setMode = setMode !== null ? setMode : !isNightModeOn
        setIsNightModeOn(setMode)
        document.body.classList.toggle(styles.invertColors, setMode)

        // // workaround due to issue described in
        // // https://stackoverflow.com/questions/52937708/why-does-applying-a-css-filter-on-the-parent-break-the-child-positioning
        // const fbBoxContainer = document.getElementById('fb_box_container')
        // fbBoxContainer!.style.right = '0px'

        window.localStorage.setItem(LOCALSTORAGE_KEY_NAME, setMode ? '1' : '')
    }

    return (
        <Image
            title="Zmień kontrast"
            onClick={() => toggleInvertColors()}
            src={isNightModeOn ? '/sun.svg' : '/moon.svg'}
            className={styles.icon}
            alt={isNightModeOn ? 'sun' : 'moon'}
        />
    )
}

export default DayNightButton
