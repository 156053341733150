import React, {useEffect, useMemo, useState} from 'react'
import Modal from 'react-modal'

import Image from '../Image'
import styles from './TrackConfirmationModal.module.scss'

interface TrackConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
}

const TrackConfirmationModal = (props: TrackConfirmationModalProps) => {
    const { isOpen, onClose } = props

    const modalStyles = useMemo(() => {
        return {
            content: {
                width: '85%',
                margin: 'auto',
                maxWidth: '800px',
                // marginTop: '45px',
                overflow: 'hidden',
                height: 'fit-content',
                transition: 'all 2s ease-in-out',
                padding: 0,
                marginTop: '3rem',
                // opacity: isOpen ? 1 : 0,
                // ...(isOpen ? { marginTop: '-20rem', } : { marginTop: '3rem', }),
            },
        }
    }, [isOpen])

    const handleClose = () => {
        onClose()
    }

    const content = (
        <>
            <div className={styles.header}>
                <p className={styles.title}>
                    Dziękujemy za dokonanie rezerwacji
                </p>
                <div className={styles.actionButtons}>
                    <Image src="/close.svg" onClick={handleClose} className={styles.closeButton} />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.description}>
                    Na Twój adres e-mail zostało wysłane potwierdzenie.<br />
                    W przypadku pytań lub problemów prosimy o kontakt na adres: <a href="mailto:tor@bosir.bialystok.pl">tor@bosir.bialystok.pl</a> lub telefonicznie: 699 677 115.
                </div>
            </div>
        </>
    )

    return (
        <Modal isOpen={isOpen} style={modalStyles} ariaHideApp={false}>
            {content}
        </Modal>
    )
}

export default TrackConfirmationModal
