import styles from './DaySchedule.module.scss'
import cn from 'classnames'
import { LANG_DAYS_FULL_PL, LANG_DAYS_PL } from '../Calendar/calendar.types'
import { useEffect, useRef, useState } from 'react'
import Button from '../Button'
import { fetchSchedulesCalendar } from '../../api/api'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import Loader from '../Loader'
import { ucFirst } from '../../common/utils'

interface DayScheduleProps {
    scheduleId: number | null
    date: Date
}

const DaySchedule = ({ scheduleId, date }: DayScheduleProps) => {
    const lineHeight = 60
    const [isOpenBlock, setIsOpenBlock] = useState<string | null>(null)
    const [day, setDay] = useState<Date>(date)
    const [schedule, setSchedule] = useState<any>(null)
    const naiveIsMobile = window.innerWidth <= 768
    const [allEventsByDate, setAllEventsByDate] = useState<{ [key: string]: any[] }>({})
    const tableRef = useRef<HTMLTableElement>(null)

    const defaultColor = '#dc0072'
    const typeToColor: { [key: string]: string } = {
        'nauka pływania': '#904873',
        'aqua aerobik': '#980058',
        'zajęcia o charakterze rehabilitacyjnym': '#5B4B8A',
        'nauka jazdy na łyżwach': '#7858A6',
        'trening hokejowy': '#ff53c7',
        'swobodna jazda gokartowa': '#FBCAFF',
        'swobodna jazda motocyklowa': '#FFADF0',
        'jazda w poślizgu - drift': '#FC28FB',
        'swobodna jazda samochodowa': defaultColor,
        'jazda dla początkujących': defaultColor,
        inne: defaultColor,
        rezerwacja: '#ba1e85',
        'wejścia biletowane': '#b22dff',
        'przerwa techniczna': '#5c0047',
        zamkniete: '#f2f2f200',
    }

    const typeShortcut: { [key: string]: string } = {
        'nauka pływania': 'NP',
        'aqua aerobik': 'A',
        'zajęcia o charakterze rehabilitacyjnym': 'RH',
        'nauka jazdy na łyżwach': 'NJ',
        'trening hokejowy': 'TH',
        rezerwacja: 'R',
        'wejścia biletowane': 'B',
        'przerwa techniczna': 'PT',
    }

    let blockTimeout: NodeJS.Timeout | null = null

    const nameFacilityBlacklist = ['brodzik', 'lodowisko', 'ślizgawka']

    useEffect(() => {
        if (isOpenBlock) {
            if (blockTimeout) clearTimeout(blockTimeout)
            blockTimeout = setTimeout(() => setIsOpenBlock(null), 30000)
        }
    }, [isOpenBlock])

    const isNameOnBlackList = (name: string): boolean => {
        return !!nameFacilityBlacklist.find(
            (e) => name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        )
    }

    const getDate = (date: Date) =>
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()

    useEffect(() => {
        if (scheduleId) {
            ;(async () => {
                const schedule = await fetchSchedulesCalendar(scheduleId)
                setSchedule(schedule)
                const eventsByDate = schedule?.schedules?.reduce((acc: any, item: any) => {
                    const longDate = new Date(item.startAt)
                    const date = getDate(longDate)

                    return {
                        ...acc,
                        [date]: acc[date] ? [...acc[date], item] : [item],
                    }
                }, {})
                setAllEventsByDate(eventsByDate)
            })()
        }
    }, [scheduleId])

    useEffect(() => {
        setDay(date)
    }, [date])

    const renderTracks = (withName: boolean = false, isClosed: boolean = false): any[] => {
        if (!schedule) {
            return []
        }
        let tracks = []
        for (let i = 0; i < schedule.tracksCount; i++) {
            tracks.push(
                withName ? (
                    <td
                        key={`td-${i}`}
                        className={cn(
                            `track-${i}`,
                            i === 0 && styles.trackFirst,
                            i === schedule.tracksCount - 1 && styles.trackLast
                        )}
                    >
                        {isNameOnBlackList(schedule.name) ? '' : `Tor ${i + 1}`}
                        {renderSchedule(i + 1)}
                        {!isClosed && i === 0 && renderDisabledOpen()}
                        {!isClosed && i === 0 && renderDisabledClose()}
                    </td>
                ) : (
                    <td></td>
                )
            )
        }

        return tracks
    }

    const renderTracksMobile = (withName: boolean = false): any[] => {
        if (!schedule) {
            return []
        }
        let tracks = []
        for (let i = 0; i < schedule.tracksCount; i++) {
            tracks.push(
                withName ? (
                    <tr
                        key={`tr-${i}`}
                        className={cn(
                            `track-${i}`,
                            i === 0 && styles.trackFirst,
                            i === schedule.tracksCount - 1 && styles.trackLast,
                            schedule.tracksCount < 2 && styles.singleTrack
                        )}
                    >
                        <td>
                            {isNameOnBlackList(schedule.name) ? '' : `Tor ${i + 1}`}
                            {renderScheduleMobile(i + 1)}
                            {i === 0 && renderDisabledOpen()}
                            {i === 0 && renderDisabledClose()}
                        </td>
                    </tr>
                ) : (
                    <tr></tr>
                )
            )
        }

        return tracks
    }

    const renderDisabledClose = () => {
        const closeAt = schedule?.closedAt || '23:59'
        const openAt = schedule?.openAt || '00:00'
        const minutes = +openAt.split(':')[1]
        if (minutes < 15) {
            return ''
        }

        return (
            <div
                key="disabled-closed"
                title={`Obiekt czynny do: ${closeAt}`}
                className={cn(styles.block, styles.blockDisabled)}
                style={
                    naiveIsMobile
                        ? {
                              width: lineHeight - closeAt.split(':')[1],
                              height: `calc(${schedule.tracksCount * 100}% + ${
                                  schedule.tracksCount
                              }px)`,
                              left: `calc(${(+closeAt.split(':')[0] - +openAt.split(':')[0] + 2) * lineHeight}px + ${4 + +closeAt.split(':')[1]}px)`,
                              writingMode: 'vertical-rl',
                              fontSize: minutes < 30 ? '100%' : 'unset',
                              paddingLeft: '3px',
                          }
                        : {
                              height: lineHeight - closeAt.split(':')[1],
                              width: `calc(${schedule.tracksCount * 100}% + ${
                                  schedule.tracksCount
                              }px)`,
                              top: `calc(calc(${
                                  closeAt.split(':')[0] - +openAt.split(':')[0] + 1
                              } * ${lineHeight}px) + ${closeAt.split(':')[1]}px)`,
                          }
                }
            >
                <span>Obiekt czynny do {closeAt}</span>
            </div>
        )
    }

    const renderDisabledOpen = () => {
        const openAt = schedule?.openAt || '00:00'
        const minutes = +openAt.split(':')[1]
        if (minutes < 15) {
            return ''
        }
        return (
            <div
                key="disabled-open"
                title={`Obiekt czynny od: ${openAt}`}
                className={cn(styles.block, styles.blockDisabled)}
                style={
                    naiveIsMobile
                        ? {
                              width: minutes || 0,
                              height: `calc(${schedule.tracksCount * 100}% + ${
                                  schedule.tracksCount
                              }px)`,
                              left: lineHeight,
                              writingMode: 'vertical-rl',
                              fontSize: minutes < 30 ? '100%' : 'unset',
                              paddingLeft: '3px',
                          }
                        : {
                              height: minutes || 0,
                              width: `calc(${schedule.tracksCount * 100}% + ${
                                  schedule.tracksCount
                              }px)`,
                              top: lineHeight,
                          }
                }
            >
                <span>Obiekt czynny od {openAt}</span>
            </div>
        )
    }

    const renderHours = (isClosed: boolean = false) => {
        if (!schedule) {
            return []
        }
        let hours = []
        const openAt = schedule?.openAt?.split(':') || [0, 0]
        const closeAt = schedule?.closedAt?.split(':') || [24, 0]
        const lastClass = `row-${+closeAt[1]}`
        for (let i = +openAt[0]; i <= +closeAt[0]; i++) {
            hours.push(
                <tr className={i > +closeAt[0] ? lastClass : ''}>
                    <td>{i}:00</td>
                    {renderTracks(false, isClosed)}
                </tr>
            )
        }
        return hours
    }

    const renderHoursMobile = () => {
        if (!schedule) {
            return []
        }
        let hours = []
        const openAt = schedule?.openAt?.split(':') || [0, 0]
        const closeAt = schedule?.closedAt?.split(':') || [24, 0]
        for (let i = +openAt[0]; i <= +closeAt[0]; i++) {
            hours.push(<th key={`th-${i}`}>{i}:00</th>)
        }
        return hours
    }

    const renderBlock = (scheduleBlock: any, track: number, index: number) => {
        if (!schedule) {
            return null
        }
        const openAt = schedule?.openAt?.split(':') || [0, 0]

        const startAt = moment(scheduleBlock.startAt)
        const endAt = moment(scheduleBlock.endAt)
        const label = scheduleBlock.reserveName || ucFirst(scheduleBlock.type)
        const blockId = label + scheduleBlock.startAt + scheduleBlock.endAt + track.toString()
        const top = (1 + startAt.hours() - openAt[0]) * lineHeight + startAt.minutes()
        const height = (endAt.hours() - startAt.hours()) * lineHeight + endAt.minutes() - startAt.minutes()
        const startAtFormat = startAt.format('HH:mm')
        const endAtFormat = endAt.format('HH:mm')

        const bgColor = typeToColor[scheduleBlock.type] || defaultColor
        if (typeof scheduleBlock.tracksReserved === 'string') {
            scheduleBlock.tracksReserved = scheduleBlock.tracksReserved
                .split(',')
                .map((track: string) => +track)
        }
        const tracksReserved = scheduleBlock.tracksReserved.filter((t: number) => t > 0)

        if (track > 0 && tracksReserved.indexOf(track - 1) !== -1) {
            return null
        }

        let trackLength = 0
        for (let i = track; i < 50; i++) {
            if (tracksReserved.indexOf(i) !== -1) {
                trackLength++
            } else {
                break
            }
        }

        return (
            <div
                key={blockId}
                id={blockId}
                onClick={(e) => {
                    setIsOpenBlock(blockId)
                }}
                className={styles.block}
                style={{
                    height: height,
                    width: `calc(${trackLength * 100}% + ${trackLength}px)`,
                    top: top,
                    zIndex: 1000 + (isOpenBlock === blockId ? 5 : 0),
                    backgroundColor: bgColor,
                }}
                data-timefrom={scheduleBlock.closed ? '' : startAtFormat}
                data-timeto={scheduleBlock.closed ? '' : endAtFormat}
            >
                {!scheduleBlock.closed && isOpenBlock === blockId && (
                    <div>
                        {`${
                            scheduleBlock.type.toLowerCase() !== label.toLowerCase()
                                ? `${ucFirst(scheduleBlock.type)}: ${label} -`
                                : `${label}:`
                        } od ${startAtFormat} do ${endAtFormat}`}
                    </div>
                )}
                <span>
                    {scheduleBlock.closed
                        ? 'Obiekt nieczynny'
                        : naiveIsMobile && height < 70
                        ? typeShortcut[scheduleBlock.type] || 'ⓘ'
                        : label}
                </span>
            </div>
        )
    }

    const renderBlockMobile = (scheduleBlock: any, track: number, index: number) => {
        const lineWidth = 60
        if (!schedule) {
            return null
        }
        const openAt = schedule?.openAt?.split(':') || [0, 0]

        const startAt = moment(scheduleBlock.startAt)
        const endAt = moment(scheduleBlock.endAt)
        const label = scheduleBlock.reserveName || ucFirst(scheduleBlock.type)
        const blockId = label + scheduleBlock.startAt + scheduleBlock.endAt + track.toString()
        const left = (1 + startAt.hours() - openAt[0]) * lineWidth + startAt.minutes()
        const width =
            (endAt.hours() - startAt.hours()) * lineWidth + endAt.minutes() - startAt.minutes()
        const startAtFormat = startAt.format('HH:mm')
        const endAtFormat = endAt.format('HH:mm')

        const bgColor = typeToColor[scheduleBlock.type] || defaultColor

        return (
            <div
                key={blockId}
                id={blockId}
                onClick={(e) => {
                    const left = parseInt(e.currentTarget.style.left)
                    setTimeout(() => {
                        if (tableRef?.current?.parentElement) {
                            tableRef.current.parentElement.scrollLeft = left - 128
                        }
                    })
                    setIsOpenBlock(blockId)
                }}
                className={styles.block}
                style={{
                    width,
                    left,
                    zIndex: 1000 + (isOpenBlock === blockId ? 5 : 0),
                    backgroundColor: bgColor,
                }}
                data-timefrom={scheduleBlock.closed ? '' : startAtFormat}
                data-timeto={scheduleBlock.closed ? '' : endAtFormat}
            >
                {!scheduleBlock.closed && isOpenBlock === blockId && (
                    <div>
                        {`${
                            scheduleBlock.type.toLowerCase() !== label.toLowerCase()
                                ? `${ucFirst(scheduleBlock.type)}: ${label} -`
                                : `${label}:`
                        } od ${startAtFormat} do ${endAtFormat}`}
                    </div>
                )}
                <span>
                    {scheduleBlock.closed
                        ? 'Obiekt nieczynny'
                        : naiveIsMobile && width < 70
                        ? typeShortcut[scheduleBlock.type] || 'ⓘ'
                        : label}
                </span>
            </div>
        )
    }

    const renderSchedule = (track: number) => {
        if (allEventsByDate[getDate(day)]) {
            return allEventsByDate[getDate(day)]
                .filter((s) => s.tracksReserved.indexOf(track) !== -1)
                .map((block, index) => renderBlock(block, track, index))
        }

        return null
    }

    const renderScheduleMobile = (track: number) => {
        if (allEventsByDate[getDate(day)]) {
            return allEventsByDate[getDate(day)]
                .filter((s) => s.tracksReserved.indexOf(track) !== -1)
                .map((block, index) => renderBlockMobile(block, track, index))
        }

        return null
    }

    const getMobileLegend = () => {
        if (['lodowisko', 'ślizgawka'].includes(schedule.name.toLowerCase())) {
            return <ul style={{listStyle: 'none'}}>
                <li>R – rezerwacja</li>
                <li>PT – przerwa techniczna</li>
                <li>B – wejścia biletowane</li>
                <li>TH – trening hokejowy</li>
                <li>NJ – nauka jazdy na łyżwach</li>
            </ul>
        }

        return <ul style={{listStyle: 'none'}}>
            <li>R – rezerwacja</li>
            <li>NP – nauka pływania</li>
            <li>A – aqua aerobik</li>
            <li>PT – przerwa techniczna</li>
            <li>RH – zajęcia o charakterze rehabilitacyjnym</li>
        </ul>
    }

    const Schedule = () => {
        const isClosed = allEventsByDate[getDate(day)]?.[0]?.closed
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <small>
                        Grafik na dzień:{' '}
                        <strong>{`${LANG_DAYS_FULL_PL[day.getDay()]}, ${moment(day).format(
                            'DD-MM-YYYY'
                        )}, `}</strong>
                    </small>
                    <small>
                        {isClosed
                            ? 'obiekt nieczynny'
                            : `czynne od ${schedule.openAt} do ${schedule.closedAt}`}
                    </small>
                </div>
                <Table
                    className={cn(
                        styles.table,
                        `start-disabled-${schedule.openAt.split(':')[1] || '00'}`
                    )}
                    ref={tableRef}
                    responsive
                    striped
                    bordered
                    hover
                >
                    <thead>
                        <tr>
                            <td key={'timer'} className={styles.firstCol}>
                                &#128336;
                            </td>
                            {renderTracks(true, isClosed)}
                        </tr>
                    </thead>
                    <tbody>{renderHours(isClosed)}</tbody>
                </Table>
            </div>
        )
    }

    const ScheduleMobile = () => {
        return (
            <div className={cn(styles.container, naiveIsMobile && styles.mobileView)}>
                <div className={styles.header}>
                    <small>{`${LANG_DAYS_FULL_PL[day.getDay()]} | ${moment(day).format(
                        'DD-MM-YYYY'
                    )}`}</small>
                    <small className="col-12">
                        {allEventsByDate[getDate(day)]?.[0]?.closed ? (
                            <span className="text-warning">obiekt nieczynny</span>
                        ) : (
                            `czynne od ${schedule.openAt} do ${schedule.closedAt}`
                        )}
                    </small>
                </div>
                <Table className={styles.table} ref={tableRef} responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th className={styles.firstCol}>&#128336;</th>
                            {renderHoursMobile()}
                        </tr>
                    </thead>
                    <tbody>{renderTracksMobile(true)}</tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={8} style={{textAlign: 'left'}}>
                                Legenda:
                                {getMobileLegend()}
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        )
    }

    return schedule ? (
        naiveIsMobile ? (
            <ScheduleMobile />
        ) : (
            <Schedule />
        )
    ) : (
        <div className={'text-center mt-5'}>
            <Loader type={1} />
        </div>
    )
}

export default DaySchedule
