import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'

import Image from '../Image'
import { openPrintDialog, createPrintHtml } from '../../common/print'
import { ITile } from '../Tile'
import styles from './TileModal.module.scss'
import { fetchTileById } from '../../api/api'
import Gallery from "../Gallery";

interface TileModalProps {
    isOpen: boolean
    onClose: () => void
    tile: ITile
}

const TileModal = (props: TileModalProps) => {
    const {
        isOpen,
        onClose,
        tile: { text: description, title, id },
    } = props

    const [tile, setTile] = useState<ITile | null>(null)

    useEffect(() => {
        ;(async () => {
            const data = await fetchTileById(id)
            setTile(data)
        })()
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            contentLabel={title}
            style={modalStyles}
            ariaHideApp={false}
            // shouldCloseOnOverlayClick={true}
        >
            <div className={styles.header}>
                <p className={styles.title}>{title}</p>
                <div className={styles.actionButtons}>
                    <Image
                        src="/printer.svg"
                        onClick={() => {
                            const html = createPrintHtml(title, description)
                            openPrintDialog(html)
                        }}
                        className={styles.printButton}
                    />
                    <Image src="/close.svg" onClick={onClose} className={styles.closeButton} />
                </div>
            </div>
            <div className={styles.content}>
                <div
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={styles.description}
                />
                <div>{tile?.gallery ? <Gallery source={Object.values(tile.gallery)} /> : 'galeria?'}</div>
            </div>
        </Modal>
    )
}

export default TileModal

const modalStyles = {
    content: {
        width: '85%',
        margin: 'auto',
        maxWidth: '700px',
        padding: 0,
        marginTop: '45px',
        height: 'fit-content',
    },
}
