import cn from "classnames";
import styles from "./Navigation.module.scss";
import Link from "../Link";
import React from "react";

const AccessibilityButton = () => {

    return (
        <Link
            href={'/deklaracja-dostepnosci-bosir-1135'}
            title={'Deklaracja dostępności'}
            className={cn(styles.handIcon, 'notInvertColors')}
        >
            <img src={'/wheelchair-icon.png'} alt={'Deklaracja dostępności'}/>
        </Link>)
}

export default AccessibilityButton;