import React from 'react'
import cn from 'classnames'

import Image from '../Image'
import Link from '../Link'

import styles from './SocialMedia.module.scss'

interface SocialMediaProps {
    inline?: boolean
}

const SocialMedia = (props: SocialMediaProps) => {
    // if (props.inline) {
    //     return (
    //         <>
    //             <Link target={'_blank'} href={'https://pl-pl.facebook.com/Miejskoaktywni/'}>
    //                 <Image src="/facebook-logo.png" className={cn(styles.icon, styles.fbIcon)} />
    //             </Link>
    //             <Link target={'_blank'} href="https://www.instagram.com/miejskoaktywni/">
    //                 <Image src="/instagram.png" className={cn(styles.icon, styles.insta)} />
    //             </Link>
    //             <Link target={'_blank'} href="https://twitter.com/miejsko">
    //                 <Image src="/twitter.png" className={cn(styles.icon, styles.twitter)} />
    //             </Link>
    //             <Link
    //                 target={'_blank'}
    //                 href="http://www.youtube.com/channel/UCLo5sBeCkpjBrx-kyXksdTg"
    //             >
    //                 <Image src="/icon-youtube.png" className={cn(styles.icon, styles.ytIcon)} />
    //             </Link>
    //         </>
    //     )
    // }

    return (
        <div className={props.inline ? styles.menuView : styles.sideView}>
            <Link target={'_blank'} href={'https://pl-pl.facebook.com/Miejskoaktywni/'}>
                <Image src="/facebook-logo.png" className={cn(styles.icon, styles.fbIcon)} />
            </Link>
            <Link target={'_blank'} href="https://www.instagram.com/miejskoaktywni/">
                <Image src="/instagram.png" className={cn(styles.icon, styles.insta)} />
            </Link>
            <Link target={'_blank'} href="https://twitter.com/miejsko">
                <Image src="/twitter.png" className={cn(styles.icon, styles.twitter)} />
            </Link>
            <Link target={'_blank'} href="http://www.youtube.com/channel/UCLo5sBeCkpjBrx-kyXksdTg">
                <Image src="/icon-youtube.png" className={cn(styles.icon, styles.ytIcon)} />
            </Link>
        </div>
    )
}

export default SocialMedia
