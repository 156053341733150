import TuiCalendar from 'tui-calendar'
import { ITile } from '../components/Tile'

const protocol = window.location.protocol === 'https:' ? 'https' : 'http'
const host = window.location.hostname || 'miejskoaktywni.pl'

// export const baseUrl = `${protocol}://miejskoaktywni.pl`
// export const baseUrl = 'http://localhost:8001'

const port = document.location.hostname === 'localhost' ? '8001' : ''
export const baseUrl = `${protocol}://${host}${port ? `:${port}` : ''}`

const apiUrl = `${baseUrl}/api`

const headers = {
    // Accept: '*/*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Authorization: `Basic ${window.btoa('miejsko:aktywni')}`,
}

const methods = {
    GET: 'GET',
    POST: 'POST',
}

export const    fetchCategories = () => {
    return fetch(`${apiUrl}/categories?isActive=1`, { method: methods.GET, headers })
        .then((res) => res.json())
        .then((data) => data)
}

export const fetchCategoryById = (categoryId: number) => {
    return fetch(`${apiUrl}/categories/${categoryId}`, { method: methods.GET, headers }).then(
        (res) => res.json()
    )
}

export const fetchFooter = () => {
    return fetch(`${apiUrl}/footers`, { method: methods.GET, headers }).then((res) => res.json())
}

export const fetchCamera = () => {
    return fetch(`${apiUrl}/cameras`, { method: methods.GET, headers }).then((res) => res.json())
}

export const fetchSchedulesCalendar = (id: number) => {
    return fetch(`${apiUrl}/schedules_calendars/${id}`, { method: methods.GET, headers }).then(
        (res) => res.json()
    )
}

export const createScheduleReservation = (payload: any) => {
    return fetch(`${apiUrl}/schedule_reservations`, {
        method: methods.POST,
        headers,
        body: JSON.stringify(payload),
    }).then((res) => res.json())
}

export const fetchActivitiesTable = () => {
    return fetch(`${apiUrl}/activities_table_items`, { method: methods.GET, headers }).then((res) =>
        res.json()
    )
}

export const fetchTiles = (categoryId: number, page: number = 1, filters: string[] | null = null) => {
    return fetch(`${apiUrl}/tiles?page=${page}&categories=${categoryId}&isActive=1&${Array.isArray(filters) ? filters.join("&") : ""}`, {
        method: methods.GET,
        headers,
    })
        .then((res) => res.json())
        .then((res) => res.filter((tile: ITile) => tile.isActive))
}
export const fetchTilesByUrl = (url?: string) => {
    return fetch(`${baseUrl}${url}`, { method: methods.GET, headers }).then((res) => res.json())
}

export const fetchTileById = (id: number) => {
    return fetch(`${apiUrl}/tiles/${id}`, {
        method: methods.GET,
        headers,
    }).then((res) => res.json())
}

export const fetchOldTilesById = (id: number) => {
    return fetch(`${apiUrl}/tiles/old/${id}`, {
        method: methods.GET,
        headers,
    }).then((res) => res.json())
}

export const searchTiles = (query: string) => {
    return fetch(`${apiUrl}/tiles/search/${query}`, { method: methods.GET, headers })
        .then((res) => res.json())
        .then((res) => res.filter((tile: ITile) => tile.isActive))
}

export const getAvailableRooms = (dateFrom: string, dateTo: string) => {
    return fetch(`${apiUrl}/rooms/available/${dateFrom}/${dateTo}`, {
        method: methods.GET,
        headers,
    }).then((res) => res.json())
}

export const createRoomReservation = (payload: any) => {
    return fetch(`${apiUrl}/room_reservations`, {
        method: methods.POST,
        headers,
        body: JSON.stringify(payload),
    }).then((res) => res.json())
}

export const fetchSchedule = (schedulePath: string, calendarId: number) => {
    return fetch(`${baseUrl}${schedulePath}`, { method: methods.GET, headers })
        .then((res) => res.json())
        .then((data) => ({
            id: data.id,
            calendarId: calendarId,
            title: data.type,
            category: 'time',
            start: data.startAt,
            end: data.endAt,
            raw: data,
        }))
}

export const reloadCalendars = (tuiCalendar: TuiCalendar, calendarId: number) => {
    return fetch(`${apiUrl}/schedules_calendars/${calendarId}`)
        .then((response) => response.json())
        .then((data) => {
            const promises: Promise<any>[] = []
            data.schedules.map((schedule: any) => {
                promises.push(fetchSchedule(schedule, calendarId))
            })

            Promise.all(promises).then((data) => tuiCalendar.createSchedules(data))
        })
}
