import React, { useEffect, useMemo, useState } from 'react'

import TrackModal from '../../components/TrackModal'
import TilesRow from '../../components/TilesRow'
import { fetchCategoryById, fetchSchedulesCalendar, fetchTiles } from '../../api/api'
import { getRowsWithTiles, ucFirst } from '../../common/utils'
import DatePicker from '../../components/DatePicker'
import styles from './SportFacility.module.scss'
import TilesPlaceholder from '../../components/TilesPlaceholder'
import Map from '../../components/Map'
import PagePlaceholder from '../PagePlaceholder'
import moment from 'moment'
import TrackConfirmationModal from '../../components/TrackConfirmationModal'

const getDate = (date: Date) =>
    date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()

const TrackFacility = (props: any) => {
    const { showCalendar = true, id } = props

    const [localId, setLocalId] = useState<number | null>(null)
    const [activeTile, setActiveTile] = useState<any>(undefined)
    const [tiles, setTiles] = useState([])
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])
    const [allEventsByDate, setAllEventsByDate] = useState<{ [key: string]: any[] }>({})
    const [categoryData, setCategoryData] = useState<any>(null)
    const [tooltipDate, setTooltipDate] = useState<Date | null>(null)
    const [confirmationModal, setConfirmationModal] = useState<boolean>(
        window.location.search === '?potwierdzenie'
    )

    const tempMapNames: any = {
        tor: 'Tor Białystok',
    }

    useEffect(() => {
        if (localId !== id && !isNaN(id)) {
            // console.log('FETCH CATEG', localId, id)
            setLocalId(id)
            ;(async () => {
                const data = await fetchCategoryById(id)
                setCategoryData(data)

                const dataTiles = await fetchTiles(id)
                const schedule = await fetchSchedulesCalendar(data.calendar[0]?.id)
                const eventsByDate = schedule?.schedules?.reduce((acc: any, item: any) => {
                    const longDate = new Date(item.startAt)
                    const date = getDate(longDate)

                    return {
                        ...acc,
                        [date]: acc[date] ? [...acc[date], item] : [item],
                    }
                }, {})
                setAllEventsByDate(eventsByDate)
                setTiles(dataTiles)
                setRowsWithTiles(getRowsWithTiles(data.rows, dataTiles))
            })()
        }
    }, [id])

    useEffect(() => {
        const urlId = window.location.href.split('#')[1]
        if (urlId) {
            onTileClick(+urlId)
        }
        if (activeTile?.id && !urlId) {
            window.history.pushState({}, '', window.location.href + '#' + activeTile.id)
        }
        if (tiles.length && urlId && !tiles.find((r: any) => r.id === id)) {
            window.history.pushState({}, '', window.location.href.split('#')[0])
        }
    }, [tiles, activeTile])

    const onTileClick = (id: number) => {
        setActiveTile(tiles.find((r: any) => r.id === id))
    }

    const activeDates = Object.values(allEventsByDate)
        .flat()
        .map((d) => new Date(d.startAt))
        .filter((d) => d > new Date())

    const TooltipDate = () => {
        if (!tooltipDate || !allEventsByDate[getDate(tooltipDate)]) {
            return null
        }
        const data = allEventsByDate[getDate(tooltipDate)]?.map((event) => (
            <div key={event.id}>
                <p>
                    <strong>Nazwa</strong>: {ucFirst(event.type)}
                </p>
                <p>
                    <strong>Opis</strong>: {event.reserveName}
                </p>
                <p>
                    <strong>Godzina rozpoczęcia</strong>: {moment(event.startAt).format('HH:mm')}
                </p>
                <p>
                    <strong>Godzina zakończenia</strong>: {moment(event.endAt).format('HH:mm')}
                </p>
                <p>
                    <strong>Cena</strong>: {(event.price / 100).toFixed(2)} zł
                </p>
                <p>
                    <strong>Rezerwacje</strong>: {event.reservedSeats} / {event.seatsTaken}
                </p>
            </div>
        ))

        return (
            <div className={styles.tooltipDate}>
                <h6>Harmonogram na dzień: {moment(tooltipDate).format('DD-MM-YYYY')}</h6>
                {data}
            </div>
        )
    }

    const modalStyles = {
        content: {
            width: '85%',
            margin: 'auto',
            maxWidth: '800px',
            // marginTop: '45px',
            overflow: 'hidden',
            height: 'fit-content',
            transition: 'all 2s ease-in-out',
            padding: 0,
            marginTop: '3rem',
            // opacity: isOpen ? 1 : 0,
            // ...(isOpen ? { marginTop: '-20rem', } : { marginTop: '3rem', }),
        },
    }

    if (!categoryData) {
        return <PagePlaceholder />
    }

    return (
        <div className={styles.container}>
            {categoryData.mainImagePath?.src ? (
                <div className={styles.mainImageContainer}>
                    <div
                        className={styles.mainImage}
                        style={{
                            backgroundImage: `url('https://miejskoaktywni.pl${categoryData.mainImagePath.src}`,
                        }}
                    ></div>
                    <div className={styles.mainTitle}>
                        {(
                            tempMapNames[categoryData.name.toLowerCase()] || categoryData.name
                        ).toUpperCase()}
                    </div>
                </div>
            ) : (
                <div className={styles.mainTitle}>
                    {(
                        tempMapNames[categoryData.name.toLowerCase()] || categoryData.name
                    ).toUpperCase()}
                </div>
            )}

            <section
                dangerouslySetInnerHTML={{ __html: categoryData?.shortDescription }}
                className="mb-5 px-2"
            ></section>

            {showCalendar && isModalOpen && allEventsByDate?.[getDate(selectedDate)] && (
                <TrackModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    events={allEventsByDate[getDate(selectedDate)]}
                />
            )}
            <div className={styles.tilesRows}>
                {rowsWithTiles?.map((r: any, i: number) => (
                    <TilesRow key={i} row={r} onTileClick={onTileClick}>
                        {showCalendar && i === 0 ? (
                            <div className={styles.trackCalendar}>
                                {tooltipDate && <TooltipDate />}
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date: Date) => {
                                        setTooltipDate(null)
                                        setSelectedDate(date)
                                        setIsModalOpen(true)
                                    }}
                                    onDayMouseOver={(date) => setTooltipDate(date)}
                                    onDayMouseOut={() => setTooltipDate(null)}
                                    numberOfMonths={3}
                                    monthsShown={1}
                                    highlightDates={activeDates}
                                />
                            </div>
                        ) : undefined}
                    </TilesRow>
                )) || TilesPlaceholder()}
            </div>

            <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: categoryData?.description }}
            />

            {categoryData?.longitude && categoryData?.latitude && (
                <section>
                    <div className={styles.contact}>
                        <span>Gdzie nas znajdziesz</span>
                    </div>
                    <Map longitude={categoryData.longitude} latitude={categoryData.latitude} />
                </section>
            )}
            {confirmationModal && (
                <TrackConfirmationModal
                    isOpen={confirmationModal}
                    onClose={() => setConfirmationModal(false)}
                />
            )}
        </div>
    )
}

export default TrackFacility
