import React, { useEffect, useState } from 'react'
import TilesRow from '../../components/TilesRow'
import { fetchCategoryById, fetchTiles } from '../../api/api'
import { Page } from '../interfaces'
import Map from '../../components/Map'

import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import { getRowsWithTiles } from '../../common/utils'

import styles from './SportFacility.module.scss'
import PagePlaceholder from '../PagePlaceholder'
import CustomCalendar from '../../components/CustomCalendar'
import DaySchedule from '../../components/DaySchedule'
import { Tabs, Tab } from 'react-bootstrap'

interface SportFacilityProps extends Page {}

const SportFacility = (props: SportFacilityProps) => {
    const { id } = props
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [key, setKey] = useState('calendar-0')

    // hardcoded special events
    const isSectionEnabled = (sectionName: string) => {
        return !(
            sectionName === 'schedules' &&
            categoryData?.layoutScheduleHidden === true
        )
    }

    const tempMapNames: any = {
        rodzinna: 'Pływalnia rodzinna',
        sportowa: 'Pływalnia sportowa',
        kameralna: 'Pływalnia kameralna',
        plaża: 'PLAŻA DOJLIDY',
    }

    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])
    const [categoryData, setCategoryData] = useState<any>(null)

    useEffect(() => {
        ;(async () => {
            const data = await fetchCategoryById(id)
            setCategoryData(data)
        })()
    }, [id])

    useEffect(() => {
        if (categoryData) {
            ;(async () => {
                const tilesData = await fetchTiles(id)
                setRowsWithTiles(getRowsWithTiles(categoryData.rows, tilesData))
            })()
        }
    }, [categoryData])

    const onClickDay = (day: Date) => {
        setSelectedDate(day)
    }

    const facilityNameSwap = (name: string): string => {
        const names: { [key: string]: string } = {
            'Basen sportowy na Pływalni Rodzinnej': 'Basen sportowy',
            'Basen sportowy na Pływalni Sportowej': 'Basen sportowy',
        }
        return names[name] || name
    }

    const renderSchedulesTabs = () => {
        return (
            <section className="mb-5">
                <CustomCalendar type={'week'} onClickDay={onClickDay} selectedDay={selectedDate} />
                <section
                    dangerouslySetInnerHTML={{ __html: categoryData?.shortDescription }}
                    className="mb-5 px-1"
                ></section>
                <Tabs
                    id="calendar-tabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k || 'calendar-0')}
                    className="mb-3 calendar-tabs"
                >
                    {categoryData?.calendar.map((c: any, i: number) => (
                        <Tab eventKey={`calendar-${i}`} title={facilityNameSwap(c.name)}>
                            <DaySchedule scheduleId={c.id || null} date={selectedDate} />
                        </Tab>
                    ))}
                </Tabs>
            </section>
        )
    }

    if (!categoryData) {
        return <PagePlaceholder />
    }

    const renderHeader = () => {
        return categoryData.mainImagePath?.src ? (
            <div className={styles.mainImageContainer}>
                <div
                    className={styles.mainImage}
                    style={{
                        backgroundImage: `url('https://miejskoaktywni.pl${categoryData.mainImagePath.src}`,
                    }}
                ></div>
                <div className={styles.mainTitle}>
                    {(
                        tempMapNames[categoryData.name.toLowerCase()] || categoryData.name
                    ).toUpperCase()}
                </div>
            </div>
        ) : (
            <div className={styles.mainTitle}>
                {(tempMapNames[categoryData.name.toLowerCase()] || categoryData.name).toUpperCase()}
            </div>
        )
    }

    // const renderShortDescription1 = () => {
    //     return (
    //         <section
    //             dangerouslySetInnerHTML={{ __html: categoryData?.shortDescription }}
    //             className="mb-5 px-2"
    //         ></section>
    //     )
    // }

    const renderShortDescription2 = () => {
        return (
            <section
                dangerouslySetInnerHTML={{ __html: categoryData?.shortDescription2 }}
                className="mb-5 px-2"
            ></section>
        )
    }


    const renderSchedules = () => {
        return isSectionEnabled('schedules') ? (
            <section>
                {categoryData?.calendar?.length === 1 && (
                    <section className="mb-5">
                        <CustomCalendar
                            type={'week'}
                            onClickDay={onClickDay}
                            selectedDay={selectedDate}
                        />
                        <section
                            dangerouslySetInnerHTML={{ __html: categoryData?.shortDescription }}
                            className="mb-1 px-1"
                        ></section>
                        <DaySchedule
                            scheduleId={categoryData?.calendar[0]?.id || null}
                            date={selectedDate}
                        />
                    </section>
                )}
                {categoryData?.calendar?.length > 1 && renderSchedulesTabs()}
            </section>
        ) : null
    }

    const renderTiles = () => {
        return (
            <section>
                {rowsWithTiles?.map((r: any, i: number) => (
                    <TilesRow key={i} row={r} />
                ))}
            </section>
        )
    }

    const renderDescription = () => {
        return (
            <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: categoryData?.description }}
            />
        )
    }

    const renderMap = () => {
        return (
            categoryData?.longitude &&
            categoryData?.latitude && (
                <section>
                    <div className={styles.contact}>
                        <span>Gdzie nas znajdziesz</span>
                    </div>
                    <Map longitude={categoryData.longitude} latitude={categoryData.latitude} />
                </section>
            )
        )
    }

    return (
        <div className={styles.container}>
            {renderHeader()}
            {renderShortDescription2()}
            {renderSchedules()}
            {/*{renderShortDescription1()}*/}
            {renderTiles()}
            {renderDescription()}
            {renderMap()}
        </div>
    )
}

export default SportFacility
