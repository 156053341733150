function closePrint(iframe: HTMLIFrameElement) {
    return () => document.body.removeChild(iframe)
}

export function openPrintDialog(html: string) {
    const hiddenPrintIFrame = document.createElement('iframe')

    hiddenPrintIFrame.style.visibility = 'hidden'
    hiddenPrintIFrame.style.position = 'fixed'
    hiddenPrintIFrame.style.right = '0'
    hiddenPrintIFrame.style.bottom = '0'

    document.body.appendChild(hiddenPrintIFrame)

    const contentWindow = hiddenPrintIFrame.contentWindow

    if (contentWindow) {
        contentWindow.document.open("text/html")
        contentWindow.document.write(html)
        contentWindow.document.close()

        hiddenPrintIFrame.onload = () => {
            contentWindow.onbeforeunload = closePrint(hiddenPrintIFrame)
            ;(contentWindow as any).onafterprint = closePrint(hiddenPrintIFrame)

            contentWindow.focus()
            ;(contentWindow as any).print()
        }
    }
}

export const createPrintHtml = (title: string, description: string) => `<!DOCTYPE html>
<html>
<head>
    <style>
      @page {
        size: A4;
        margin: 40px 0px 10px;
    }

    @media print {
        html, body {
            width: 210mm;
            height: 297mm;
        }
    }

    * {
        box-sizing: border-box;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    html, body {
        width: 100%;
        height: 100%;
        font-family: "Lato", Arial, sans-serif;
        font-size: 10px;
        margin: 0;
        padding: 0;
    }

    .page {
        width: 210mm;
        max-height: 297mm;
        padding: 20px 40px 0px 40px;
    }
    
    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    
    .description {
        font-size: 14px;
    }
    </style>
</head>
<body>
<div class="page">
    <div class="title">
    ${title}
</div>
<div class="description">
    ${description}
</div>
</div>
    </body>
    </html>`
