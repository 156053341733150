import { ReactNode, useState } from 'react'

import styles from './CustomCheckbox.module.scss'
import cn from 'classnames'

interface CustomCheckboxProps {
    value: string | number
    label: string | ReactNode
    name: string
    isRequired?: boolean
    classname?: string
    onClick?: (e: any) => void
    onChange?: (e: any) => void
    checked?: boolean
}

const CustomCheckbox = (props: CustomCheckboxProps) => {
    const {
        value,
        label,
        name,
        isRequired = false,
        classname,
        onClick = () => {},
        onChange = () => {},
        checked = false,
    } = props

    const [isChecked, setIsChecked] = useState(checked)

    return (
        <label className={styles.label}>
            <input
                type="checkbox"
                onClick={onClick}
                className={cn(styles.input, classname)}
                name={name}
                value={value}
                required={isRequired}
                onChange={(e) => {
                    setIsChecked(!isChecked)
                    onChange(e)
                }}
            />
            <span className={cn(styles.span, isChecked && styles.active)} aria-hidden="true"></span>
            <div>{label}</div>
        </label>
    )
}

export default CustomCheckbox
