import React, { useEffect, useRef, useState } from 'react'

import NavigationItem, { NavigationItemProps } from './NavigationItem'
import Burger from './Burger'
import Search from './Search'
import Image from '../Image'
import Link from '../Link'
import DayNightButton from './DayNightButton'
import styles from './Navigation.module.scss'
import FontSizeButton from './FontSizeButton'
import AccessibilityButton from './AccessibilityButton'
import cn from 'classnames'
import SocialMedia from '../SocialMedia'
import RodoButton from './RodoButton'

interface NavigationProps {
    categories: NavigationItemProps[]
}

const Navigation = (props: NavigationProps) => {
    const { categories } = props
    const navigationItemsRef = useRef<HTMLDivElement>(null)

    const toggleMenu = () => {
        navigationItemsRef?.current?.classList.toggle(styles.show)
    }

    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <Link href="/">
                    <Image
                        className={cn(styles.logo, 'notInvertColors)')}
                        src="/logo-white.png"
                        alt="logo"
                    />
                </Link>
                <div ref={navigationItemsRef} className={styles.navigationItems}>
                    {categories.length ? (
                        categories
                            .filter((item) => item.isMenu)
                            .filter((item) => item.label)
                            .map((item, i) => (
                                <NavigationItem
                                    key={i}
                                    label={item.label}
                                    path={item.path}
                                    subItems={item.subItems}
                                    id={item.id}
                                    onClick={toggleMenu}
                                />
                            ))
                    ) : (
                        <div className="w-75"></div>
                    )}
                    <NavigationItem itemClassname={styles.searchItem} id={-1}>
                        <Search />
                    </NavigationItem>
                    <div className={styles.menuIcons}>
                        <SocialMedia inline={true} />
                        <div className={styles.menuIconsTopMobile}>
                            <DayNightButton />
                            <FontSizeButton />
                            <AccessibilityButton />
                            <RodoButton />
                            <Link
                                target={'_blank'}
                                className={styles.logoBip}
                                href="https://bosirbip.um.bialystok.pl/"
                            ></Link>
                        </div>
                    </div>
                </div>
                <Burger toggleMenu={toggleMenu} />
            </div>
            <div className={cn(styles.menuIcons, styles.menuIconsTop)}>
                <DayNightButton />
                <FontSizeButton />
                <AccessibilityButton />
                <RodoButton />
                <Link
                    target={'_blank'}
                    className={styles.logoBip}
                    href="https://bosirbip.um.bialystok.pl/"
                ></Link>
            </div>
        </div>
    )
}

export default Navigation
