import {ITile} from "../components/Tile";

const sliceIntoChunks = (arr: any, chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export const getRowsWithTiles = (rows: any, tiles: any) => {
    const tilesCopy = [...tiles].sort((a:ITile,b:ITile) => (a?.published || a.id) < (b.published || b.id) ? 1 : -1)
    const rowsWithTiles: any = []
    rows?.forEach((r: any) => {
        rowsWithTiles.push(tilesCopy.splice(0, r.tilesCount))
    })
    const chunks = sliceIntoChunks(tilesCopy, 3)

    return [...rowsWithTiles, ...chunks]
}
export const ucFirst = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
