import React, { useEffect, useState } from 'react'

import TilesRow from '../../components/TilesRow'
import { fetchTiles } from '../../api/api'
import { Page } from '../interfaces'
import TilesPlaceholder from "../../components/TilesPlaceholder";
import { getRowsWithTiles } from "../../common/utils";

interface MainProps extends Page {}

const Main = (props: MainProps) => {
    const { id, rows } = props

    const [rowsWithTiles, setRowsWithTiles] = useState<any>([])
    const [noTiles, setNoTiles] = useState<boolean>(false);
    
    useEffect(() => {
        ;(async () => {
            const data = await fetchTiles(id)
            const dataTiles = data
            setRowsWithTiles(getRowsWithTiles(rows, dataTiles))
            setNoTiles(!dataTiles.length)
        })()
    }, [])

    return (
        <div>
            {rowsWithTiles?.length
                ? noTiles ? '' : rowsWithTiles?.map((r: any, i: number) => (
                      <TilesRow key={i} row={r} />
                  ))
                : TilesPlaceholder()}
        </div>
    )
}

export default Main
