import React from "react";

import Button from "../Button";
import styles from './TrackModal.module.scss'
import {ucFirst} from "../../common/utils";

interface TrackEventProps {
    event: any
    setSelectedEvent: (event: any) => void
}

const getTime = (date: Date) => {
    const h = date.getHours()
    const m = date.getMinutes()
    
    return `${h < 10 ? "0"+h : h}:${m < 10 ? "0"+m : m}`
}

const TrackEvent = (props: TrackEventProps) => {
    const {
        event,
        setSelectedEvent,
    } = props
    
    const eventData = {
        "Nazwa": ucFirst(event.type),
        "Opis": event.reserveName,
        "Godz. rozpoczęcia": getTime(new Date(event.startAt)),
        "Godz. zakończenia": getTime(new Date(event.endAt)),
        "Cena": `${(event.price/100).toFixed(2)} zł`,
        // "REZERWACJE": [event.reservedSeats, event.seatsTaken],
        "Liczba zarezerwowanych miejsc": event.reservedSeats,
        "Liczba dostępnych miejsc": event.seatsTaken - event.reservedSeats,
    }

    return (
        <div className={styles.eventContainer}>
            {Object.entries(eventData).map(item => (
                    <div key={item[0]} className={styles.row}>
                        <p className={styles.label}>{item[0]}:</p>
                        <p className={styles.value}>{
                            Array.isArray(item[1])
                                ? `${item[1][0]} / ${item[1][1]}`
                                : item[1]
                        }</p>
                    </div>
                )
            )}
            <div className={styles.buttonContainer}>
                <Button
                    label="Wybierz"
                    classname={styles.button}
                    isDisabled={!(event.reservedSeats < event.seatsTaken)}
                    onClick={() => { setSelectedEvent(event) }}
                />
            </div>
        </div>
    )
}

export default TrackEvent
