import styles from "./NotFound.module.scss"
import CheckOtherSites from "../CheckOtherSites";

const NotFound = () => {
    return (
        <div>
            <div className={styles.error}>
                404 <small>niestety, nic takiego nie znaleźliśmy</small>
            </div>
            <CheckOtherSites id={1011} />
        </div>
    )
}
export default NotFound
