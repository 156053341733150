import React from "react";

interface LinkProps {
    id?: string
    href: string
    children?: JSX.Element | JSX.Element[]
    target?: string
    className?: any
    title?: string
}

const Link = (props: LinkProps) => {
    const {
        id,
        href,
        children,
        target,
        title,
        className,
    } = props
    return (
        <a id={id} href={href} title={title} target={target} className={className}>
            {children}
        </a>
    )
}

export default Link;
